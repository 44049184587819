import React from 'react';
import {
    Form, Modal,
    Row, Col,
} from 'antd';
import LogoSrc from '../../assets/images/modl-logo-grey-text-gold-image.png';
import { Input, Password, Button } from '../elements/FormElements';
import './loginForm.css';

export default function () {
    const { props } = this;
    const { form } = props;

    return (
        <div>
            <div className="loginform-wrap">
                <div className="loginform-container">
                    <div className="login-form-logo">
                        <img src={LogoSrc} alt="MODL" />
                    </div>
                    <div className="login-form-box">
                        <Form onFinish={this.handleSubmit} layout="vertical">
                            <Row>
                                <Col xs={24}>
                                    <Form.Item name="email" rules={[{ type: 'email', message: 'The input is not valid E-mail!' }, { required: true, message: 'Please input your E-mail!' }]}>
                                        <Input placeholder="Username / Email" />
                                    </Form.Item>
                                    <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                                        <Password placeholder="Password" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Button type="primary" htmlType="submit" className="wide" label="Sign in" />
                                </Col>
                                <Col xs={12}>
                                    <Button onClick={() => this.setModalForgetVisible(true)} label="Forgot password" />
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </div>
            <Modal centered width={540} footer={null} visible={this.state.modalForgetVisible} onOk={() => this.setModalForgetVisible(false)} onCancel={() => this.setModalForgetVisible(false)}>
                <div className="form-wrap">
                    <Form onFinish={this.handleForgotSubmit} layout="vertical">
                        <Form.Item label={false} name="email" rules={[{ type: 'email', message: 'The input is not valid E-mail!' }, { required: true, message: 'Please input your E-mail!' }]}>
                            <Input placeholder="Enter your email address" />
                        </Form.Item>
                        <Row>
                            <Col md={12} xs={24}>
                                <Button type="primary" htmlType="submit" className="wide" label="Reset my password" />
                            </Col>
                            <Col md={12} xs={24}>
                                <Button className="link-green btn-empty underlined" label="Cancel" onClick={() => this.setModalForgetVisible(false)} />
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </div>
    );
}
