/* global localStorage: true */
import { call, put, takeEvery, all } from 'redux-saga/effects';
import { notification } from 'antd';
import lockr from 'lockr';
import { fetchApi, fetchApiAuth } from '../utils/api';
import history from '../utils/history';

import {
    userLoginSuccess,
    userLoginFailure,
    logoutSuccess,
    myAccountSuccess,
    myAccountFailure,
    passwordResetSuccess,
    passwordResetFailure,
} from './userActions';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = require('../constants').default;

const {
    USER_LOGIN_REQUEST,
    LOGOUT_REQUEST,
    MY_ACCOUNT_REQUEST,
    PASSWORD_RESET_REQUEST,
} = require('./userActions').constants;

function saveSessionToken(action) {
    const authDate = new Date();
    lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
    lockr.set(AUTH_KEY, action.data.auth_token);
}

function* makeLoginRequest(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/login',
            body: { username: action.payload.username, password: action.payload.password },
        });
        // console.log('>>>>>>>>>>>>> 1');
        yield call(saveSessionToken, response);
        // console.log('>>>>>>>>>>>>> 2');
        yield put(userLoginSuccess(response));
        // console.log('>>>>>>>>>>>>> 3');
        history.push('/dashboard');
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Unable to login' });
        yield put(userLoginFailure(e));
    }
}

function* getMyAccountDetails(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/my-account',
            body: action.payload,
        });
        yield put(myAccountSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Unable to login' });
        yield put(myAccountFailure(e));
    }
}

function* resetPassword(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/reset-password',
            body: action.payload,
        });
        yield put(passwordResetSuccess(response));
        notification.success({ message: 'Success', description: 'We have sent you a password reset email. Please check your email.' });
    } catch (e) {
        console.log('>>>>>', e)
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Unable to login' });
        yield put(passwordResetFailure(e));
    }
}

function* logoutRequest() {
    yield put(logoutSuccess());
    localStorage.clear();
    history.push('/');
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(USER_LOGIN_REQUEST, makeLoginRequest),
        takeEvery(LOGOUT_REQUEST, logoutRequest),
        takeEvery(MY_ACCOUNT_REQUEST, getMyAccountDetails),
        takeEvery(PASSWORD_RESET_REQUEST, resetPassword),
    ]);
}
