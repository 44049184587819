import React from 'react';
import { Col, Row, Avatar } from 'antd';
import moment from 'moment';
import RobHallmark from '../../../assets/images/rob-hallmark.png';

const MessageLineItem = ({ me = true, date, message }) => {
    const decodedMessage = message ? (JSON.parse(message)).text : '';
    return (
        <Row>
            {me ? <Col xs={0} md={8} /> : null}
            <Col xs={24} md={16}>
                <div className={`message-timestamp${me ? ' me' : ''}`}>
                    <strong>{moment(date).format('HH:ss')}</strong> {moment(date).format('DD/MM/YYYY')}
                </div>
                <div className="message-line-item">
                    <div className={`message-details-message${me ? ' me' : ''}`}>
                        {decodedMessage}
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default MessageLineItem;
