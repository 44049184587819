import React from 'react';
import { map } from 'underscore';
import { Link } from 'react-router-dom';
import { Breadcrumb, Icon } from 'antd';
import './breadcrumb.css';

// Example of navigation array
// const navigation = [
//     {
//         key: 0, // optional
//         label: 'Label',
//         link: '', // optional
//         className: '', // optional
//         overlay: null, // optional
//         icon: '', // optional
//     }
// ];

export default function () {
    const { navigation, separator } = this.props;
    return (navigation
        ? (
            <Breadcrumb separator={separator} className="breadcrumb">
                {map(navigation, (nav, navIndex) => (
                    <Breadcrumb.Item key={nav.key || navIndex} overlay={nav.overlay || null}>
                        
                        {nav.link
                            ? <Link className={nav.className} to={nav.link}>{nav.label}</Link>
                            : <span className={nav.className}>{nav.label}</span>}
                    </Breadcrumb.Item>
                ))}
            </Breadcrumb>
        )
        : null);
}
