/* global localStorage: true */
import { call, put, takeEvery, all } from 'redux-saga/effects';
import { notification } from 'antd';
import { fetchApiAuth } from '../utils/api';
import history from '../utils/history';

import {
    getJobListSuccess,
    getJobListFailure,
    getJobRequirementSuccess,
    getJobRequirementFailure,
    updateJobBasketSuccess,
    updateJobBasketFailure,
    getJobDetailsSuccess,
    getJobDetailsFailure,
    getJobDaysSuccess,
    getJobDaysFailure,
    clearSelectionSuccess,
    clearSelectionFailure,
    createJobSuccess,
    createJobFailure,
    submitJobSuccess,
    submitJobFailure,
    getCompleteJobSuccess,
    getCompleteJobFailure,
    confirmJobModelSuccess,
    confirmJobModelFailure,
} from './jobActions';

const {
    GET_JOB_LIST_REQUEST,
    GET_JOB_REQUIREMENT_REQUEST,
    UPDATE_JOB_BASKET_REQUEST,
    GET_JOB_DETAILS_REQUEST,
    GET_JOB_DAYS_REQUEST,
    CLEAR_SELECTION_REQUEST,
    CREATE_JOB_REQUEST,
    SUBMIT_JOB_REQUEST,
    GET_COMPLETE_JOB_REQUEST,
    CONFIRM_JOB_MODEL_REQUEST,
} = require('./jobActions').constants;

function* getJobList(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/booker/jobs',
            params: {
                page: action?.payload?.page || 1,
            },
        });
        yield put(getJobListSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Unable to login' });
        yield put(getJobListFailure(e));
    }
}

function* getJobRequirement(action) {
    try {
        const params = action.payload.params || {}
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/job/${action.payload.slug}/requirements`,
            body: params,
        });
        yield put(getJobRequirementSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Unable to login' });
        yield put(getJobRequirementFailure(e));
    }
}

function* updateJobBasket(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/job/${action.payload.slug}/update-basket`,
            params: {
                model_id: action.payload.model_id,
            },
        });
        yield put(updateJobBasketSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Unable to login' });
        yield put(updateJobBasketFailure(e));
    }
}

function* getJobDetails(action) {
    try {
        const params = action.payload.params || {}
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/job/${action.payload.slug}/details`,
            body: params,
        });
        yield put(getJobDetailsSuccess(response));
        if (response && response.data && response.data.slug_changed && response.data.slug_changed == true) {
            const newSlug = (response.data.job_details && response.data.job_details.slug) || null;
            if (newSlug != null) {
                history.push(`/job/${newSlug}/second`);
            }
        }
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Unable to login' });
        yield put(getJobDetailsFailure(e));
    }
}

function* getJobDays(action) {
    try {
        const params = action.payload.params || {}
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/job/${action.payload.slug}/days`,
            body: params,
        });
        yield put(getJobDaysSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Unable to login' });
        yield put(getJobDaysFailure(e));
    }
}

function* clearBasket(action) {
    try {
        const params = action.payload.params || {}
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/job/${action.payload.slug}/clear-basket`,
            body: params,
        });
        yield put(clearSelectionSuccess(response));
        history.push(`/job/${action.payload.slug}/first`);
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Unable to login' });
        yield put(clearSelectionFailure(e));
    }
}

function* createNewJob(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/job/create-job',
        });
        yield put(createJobSuccess(response));
        if (response && response.data && response.data.slug) {
            history.push(`/job/${response.data.slug}/first`);
        }
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Unable to login' });
        yield put(createJobFailure(e));
    }
}

function* submitJob(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/job/${action.payload.slug}/submit`,
            body: action.payload,
        });
        yield put(submitJobSuccess(response));
        if (response && response.data && response.data.success) {
            history.push(`/job/${action.payload.slug}`);
        }
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Unable to login' });
        yield put(submitJobFailure(e));
    }
}

function* getCompleteJob(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/job/${action.payload.slug}`,
        });
        yield put(getCompleteJobSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Unable to login' });
        yield put(getCompleteJobFailure(e));
    }
}

function* bookJobModel(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/job/${action.payload.slug}/book-model`,
            body: { model_id: action.payload.model_id },
        });
        yield put(confirmJobModelSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Unable to login' });
        yield put(confirmJobModelFailure(e));
    }
}


/**
 * Watch actions
 */
export default function* jobSaga() {
    yield all([
        takeEvery(GET_JOB_LIST_REQUEST, getJobList),
        takeEvery(GET_JOB_REQUIREMENT_REQUEST, getJobRequirement),
        takeEvery(UPDATE_JOB_BASKET_REQUEST, updateJobBasket),
        takeEvery(GET_JOB_DETAILS_REQUEST, getJobDetails),
        takeEvery(GET_JOB_DAYS_REQUEST, getJobDays),
        takeEvery(CLEAR_SELECTION_REQUEST, clearBasket),
        takeEvery(CREATE_JOB_REQUEST, createNewJob),
        takeEvery(SUBMIT_JOB_REQUEST, submitJob),
        takeEvery(GET_COMPLETE_JOB_REQUEST, getCompleteJob),
        takeEvery(CONFIRM_JOB_MODEL_REQUEST, bookJobModel),
    ]);
}
