import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Pagination, Row, Spin } from 'antd';
import { PlusOutlined, LeftOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { getMessageListRequest } from '../../core/message/messageActions';
import Layout from '../../components/layout/Layout';
import Messenger from '../../components/Messenger';
import MODLLogo from '../../assets/images/modl.svg';
import history from '../../core/utils/history';
import './messages.scss';

let messageInterval = null;

const Messeages = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(state => state.message.isFetching);
    const messageList = useSelector(state => state.message.messageList);
    useEffect(() => {
        dispatch(getMessageListRequest());
        // messageInterval = setInterval(() => dispatch(getMessageListRequest()), 10000);
        return () => {
            if (messageInterval != null) {
                clearInterval(messageInterval);
                messageInterval = null;
            }
        };
    }, []);

    const backLink = (
        <Row align="middle">
            <Col xs={24} md={2}>
                <LeftOutlined style={{ fontSize: '24px' }} onClick={() => history.push('/dashboard')} />
            </Col>
            <Col xs={0} md={22}>
                <div className="back-link-header">Message Models</div>
            </Col>
        </Row>
    );
    const subHeader = null;
    const content = null;

    return (
        <Layout
            className="message"
            title={<div>Welcome</div>}
            headerTitle={<img alt="MODL" src={MODLLogo} />}
            // afterHeader={<AfterHeader content={subHeader} />}
            menu={[]}
            menuText="Your Jobs"
            menuWidth={350}
            menuPlacement="left"
            // toolbar={[]}
            floatingHeader={false}
            // footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2019</div>}
            // floatingFooter
            showFooter={false}
            // breadcrumb={navigation}
            backLink={backLink}>
            <Spin size="large" spinning={false}>
                <Messenger
                    messageList={messageList} />
            </Spin>
        </Layout>
    );
};

export default Messeages;
