import { Record } from 'immutable';

const {
    DELETE_TOKEN_REQUEST,
    DELETE_TOKEN_SUCCESS,

    SAVE_TOKEN_REQUEST,
    SAVE_TOKEN_SUCCESS,

    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAILURE,

    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,

    MY_ACCOUNT_REQUEST,
    MY_ACCOUNT_SUCCESS,
    MY_ACCOUNT_FAILURE,

    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_FAILURE,
    PASSWORD_RESET_SUCCESS,

} = require('./userActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    isLoggedIn: false,
    loginUser: '',
    userId: null,
    userSettings: {},
    userDetails: {},
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case USER_LOGIN_REQUEST:
    case DELETE_TOKEN_REQUEST:
    case SAVE_TOKEN_REQUEST:
    case LOGOUT_REQUEST:
    case MY_ACCOUNT_REQUEST:
    case PASSWORD_RESET_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);
    case PASSWORD_RESET_SUCCESS:
        return state.set('isFetching', false);
    case SAVE_TOKEN_SUCCESS:
    case USER_LOGIN_SUCCESS:
        const loginUser = payload?.data?.username || '';
        const id = payload?.data?.id || null;
        return state.set('isFetching', false)
            .set('loginUser', loginUser)
            .set('userId', id)
            .set('isLoggedIn', true);
    case MY_ACCOUNT_SUCCESS:
        return state.set('isFetching', false)
            .set('userDetails', payload.data);
    case DELETE_TOKEN_SUCCESS:
    case LOGOUT_SUCCESS:
        return state.set('isFetching', false)
            .set('loginUser', '')
            .set('userId', null)
            .set('isLoggedIn', false);
    case USER_LOGIN_FAILURE:
    case LOGOUT_FAILURE:
    case MY_ACCOUNT_FAILURE:
    case PASSWORD_RESET_FAILURE:
        return state.set('isFetching', false)
            .set('isLoggedIn', false)
            .set('error', payload);
    default:
        return state;

    }
}
