/* eslint-disable */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import lockr from 'lockr';
import { notification } from 'antd';
import { isUndefined } from 'underscore';

import NotFound from '../../pages/NotFound';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
    LOCALSTORAGE_EXPIRES,
} = require('../constants').default;

const onlyAuthorisedAllowed = () => {
    const response = lockr.get(AUTH_KEY);
    if (!isUndefined(response)) {
        const authDate = lockr.get(LOCALSTORAGE_EXPIRES_KEY);
        if (!isUndefined(authDate)) {
            const aDate = new Date(authDate);
            const aNow = new Date();
            const milliseconds = aNow - aDate;
            const difference = Math.floor(milliseconds / 1000 / 60);
            if (difference >= LOCALSTORAGE_EXPIRES) {
                notification.error({ message: 'Error', description: 'Session expired. Please login again.' });
                return false;
            }
        } else {
            notification.error({ message: 'Error', description: 'Session expired. Please login again.' });
            return false;
        }
        return true;
    }
    return false;
};

const DefaultRoute = ({ exact, path, component }) => <Route exact={exact} path={path || '/'} component={component} />;
const AuthRoute = ({ exact, path, component: Component, role = '', allowedOnlyRoles = [] }) => {
    if (allowedOnlyRoles.length > 0 && !allowedOnlyRoles.includes(role)) {
        notification.error({ message: 'Error', description: 'You are trying to access a page which either doesn\'t exist or you don\'t have sufficient permissions to access.' });
        // @todo Generates error because transitioning in the middle of component life cycle. Need to figure a clean way
        return <Redirect to="/" />;
    }

    return <Route exact={exact} path={path || '/'} render={(props) => (onlyAuthorisedAllowed() ? <Component {...props} /> : <Redirect to="/" />)} />;
}
const LogoutRoute = ({ exact, path, persistor }) => {
    lockr.rm(AUTH_KEY);
    lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
    lockr.rm(LOCALSTORAGE_EXPIRES);

    localStorage.clear();
    lockr.flush();
    persistor.flush();
    persistor.purge();
    window.location.href = '/';
}

const CoreRouter = ({ children }) => (
    <Switch>
        {children}
        <DefaultRoute component={NotFound} />
    </Switch>
);

export {
    CoreRouter,
    onlyAuthorisedAllowed,
    DefaultRoute,
    AuthRoute,
    LogoutRoute,
};
