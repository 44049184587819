import React, { useState, useMemo } from 'react';
import {
    Modal,
    Form,
    Input as AntInput,
    Button as AntButton,
    Radio as AntRadio,
    Row,
    Col,
    Checkbox as AntCheckbox,
    Slider as AntSlider,
    DatePicker as AntDatePicker,
} from 'antd';
import { PayPalButton as PayPalButtonDefault } from 'react-paypal-button-v2';
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import StripeCheckout from 'react-stripe-checkout';
import useResponsiveFontSize from '../../../core/utils/useResponsiveFontSize';
import SearchLocationInput from './SearchLocationInput';
import EditImage from '../../../assets/images/edit.png';
import MODLLogo from '../../../assets/images/modl-logo-grey-text-gold-image.png';
import './formElements.scss';

const { GOOGLE_MAPS_KEY, STRIPE_KEY, STRIPE_SECRET, PAYPAL_CLIENT_ID } = require('../../../core/constants').default;

const stripePromise = loadStripe(STRIPE_KEY);

const paypalOptions = {
    clientId: PAYPAL_CLIENT_ID, // PAYPAL_CLIENT_ID,
    currency: 'GBP',
    // disableFunding: ['card', 'credit', 'bancontact', 'sepa', 'eps', 'giropay', 'ideal', 'mybank', 'sofort'],
    disableFunding: 'card,credit,bancontact,sepa,eps,giropay,ideal,mybank,sofort',
    intent: 'capture',
};
const buttonStyles = {
    layout: 'horizontal',
    shape: 'rect',
    color: 'blue',
    label: 'pay',
    tagline: false,
    height: 40,
};

const Input = ({ ...opts }) => <AntInput {...opts} />;

const LocationInput = React.forwardRef((props, ref) => <AntInput ref={ref} {...props} />);

const Password = ({ ...opts }) => <AntInput.Password {...opts} />;

const Checkbox = ({ label, ...opts }) => <AntCheckbox {...opts}>{label}</AntCheckbox>;

const Button = ({ label, ...opts }) => <AntButton {...opts}>{label}</AntButton>;

const EditButton = ({ label, ...opts }) => <AntButton {...opts}><img alt={`Edit ${label}`} src={EditImage} width={18} height={18} /> {label}</AntButton>;

const DatePicker = ({ ...opts }) => <AntDatePicker {...opts} />;

const Slider = ({ ...opts }) => (
    <>
        <AntSlider {...opts} />
    </>
);

const GoogleLocation = ({ onUpdate, val }) => (<SearchLocationInput apiKey={GOOGLE_MAPS_KEY} onUpdate={onUpdate} val={val} />);

const PayPalButton = ({ amount, onButtonReady, paymentSuccess = (data, details) => {} }) => (
    <div className="my-paypal-button">
        <PayPalButtonDefault
            options={paypalOptions}
            style={buttonStyles}
            amount={amount}
            onApprove={(data, actions) =>
                // Capture the funds from the transaction
                actions.order.capture().then((details) => {
                    // Show a success message to your buyer
                    console.log(`Transaction completed by ${details.payer.name.given_name}`, details, data);

                    // OPTIONAL: Call your server to save the transaction
                    paymentSuccess(data, details);
                })}
            onButtonReady={onButtonReady} />
    </div>
);

/*
const StripeButtonOld = ({ onToken, amount }) => (
    <StripeCheckout
        name="MODL" // the pop-in header title
        description="MODL platform" // the pop-in header subtitle
        // image="https://stripe.com/img/documentation/checkout/marketplace.png" // the pop-in header image (default none)
        ComponentClass="div"
        label="Pay by card" // text inside the Stripe button
        panelLabel="Pay by card" // prepended to the amount in the bottom pay button
        amount={parseFloat(amount) * 100} // cents
        currency="GBP"
        stripeKey={STRIPE_KEY}
        locale="en"
        email="info@modlapp.com"
        // Note: Enabling either address option will give the user the ability to
        // fill out both. Addresses are sent as a second parameter in the token callback.
        shippingAddress={false}
        billingAddress={false}
        // Note: enabling both zipCode checks and billing or shipping address will
        // cause zipCheck to be pulled from billing address (set to shipping if none provided).
        zipCode={false}
        // alipay // accept Alipay (default false)
        // bitcoin // accept Bitcoins (default false)
        // allowRememberMe // "Remember Me" option (default true)
        token={onToken} // submit callback
        // opened={this.onOpened} // called when the checkout popin is opened (no IE6/7)
        // closed={this.onClosed} // called when the checkout popin is closed (no IE6/7)
        // Note: `reconfigureOnUpdate` should be set to true IFF, for some reason
        // you are using multiple stripe keys
        reconfigureOnUpdate={false}>
        <Button
            disabled={false}
            type="primary"
            className="button-primary"
            onClick={() => {}}
            style={{ marginTop: 10 }}
            label={<>Pay with Stripe</>} />
    </StripeCheckout>
);
*/

const StripeButtonInner = ({ paymentSuccess }) => {
    const [form] = Form.useForm();
    const stripe = useStripe();
    const elements = useElements();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleSubmit = async event => {
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        const card = elements.getElement(CardElement);
        // const { error, paymentMethod } = await stripe.createPaymentMethod({
        //     type: 'card',
        //     card,
        // });
        console.log('&&&&&&&&&&&&& STRIPE_SECRET', STRIPE_SECRET);
        stripe
            .confirmCardPayment(STRIPE_SECRET, {
                payment_method: {
                    card,
                },
            })
            .then((result) => {
                if (result.error) {
                    // Show error to your customer
                    console.log('ERROR', result);
                } else {
                    // The payment succeeded!
                    console.log('SUCCESS', result);
                }
            });

        //console.log('[PaymentMethod]', paymentMethod, error);
        setIsModalVisible(false);
        //paymentSuccess(paymentMethod);
    };
    const handleCancel = () => setIsModalVisible(false);
    const useOptions = () => {
        const fontSize = useResponsiveFontSize();
        const options = useMemo(
            () => ({
                hidePostalCode: true,
                style: {
                    base: {
                        fontSize,
                        color: '#424770',
                        letterSpacing: '0.025em',
                        fontFamily: 'Source Code Pro, monospace',
                        '::placeholder': {
                            color: '#aab7c4',
                        },
                    },
                    invalid: {
                        color: '#9e2146',
                    },
                },
            }),
            [fontSize],
        );

        return options;
    };

    const options = useOptions();

    return (
        <>
            <Button
                disabled={false}
                type="primary"
                className="button-primary stripe-button"
                onClick={() => setIsModalVisible(true)}
                style={{ marginTop: 10 }}
                label={<>Pay with Stripe</>} />
            <Modal
                title="Pay with Stripe"
                visible={isModalVisible}
                okText="Pay by card"
                onOk={handleSubmit}
                onCancel={handleCancel}>
                <form
                    onSubmit={handleSubmit}>
                    <CardElement
                        options={options} />
                </form>
            </Modal>
        </>
    );
};

const StripeButton = ({ paymentSuccess = (data) => {} }) => (
    <Elements stripe={stripePromise}>
        <StripeButtonInner paymentSuccess={paymentSuccess} />
    </Elements>
);

export {
    Input,
    Password,
    Checkbox,
    Button,
    EditButton,
    Slider,
    DatePicker,
    GoogleLocation,
    LocationInput,
    PayPalButton,
    StripeButton,
};
