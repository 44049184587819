/* global window:true */
import React, { useState, useEffect } from 'react';
import StickyBox from 'react-sticky-box';
import { contains, isEmpty, isUndefined, isArray, debounce, each } from 'underscore';
import { bindActionCreators } from 'redux';
import { Spin, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import StepFindFilter from '../forms/StepFindFilter.js';
import locationImage from '../../../assets/images/location.png';
import ModelBox from '../../../components/ModelBox';
import DeafultImage from '../../../assets/images/rectangle.png';
import './steps.scss';
import * as jobActions from '../../../core/job/jobActions';
import { getJobRequirementRequest, updateJobBasketRequest } from '../../../core/job/jobActions';
import { getFieldValue } from '../../../core/utils/helpers';

const {
    API_URL,
    cupSizes,
} = require('../../../core/constants').default;

const StepFind = ({ params }) => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    useEffect(() => {
        dispatch(getJobRequirementRequest({ slug }));
        window.scrollTo(0, 0);
    }, []);

    const onFilterFieldsChange = (changedFields, allFields) => {
        // parent filters to check
        const parentFields = ['gender', 'age', 'height', 'ethnicity', 'waist_size', 'hips', 'bra_size', 'cup_size',
            'shoe_size', 'body_type', 'hair_colour', 'hair_type', 'hair_length_male', 'hair_length_female',
            'eye_color', 'skin_tone'];

        const changeElement = changedFields && changedFields[0] && changedFields[0].name && changedFields[0].name[0];
        const changeElementValue = (changedFields && changedFields[0] && changedFields[0].value);

        if (changeElement && contains(parentFields, changeElement) && changeElementValue == true) {
            return;
        }

        const values = [];
        allFields.filter((field) => {
            if (isArray(field.value)) {
                return (field.value).length > 0;
            }
            return !isUndefined(field.value) && field.value !== false;
        }).forEach((field) => values[field.name[0]] = field.value);

        if (isUndefined(values.gender) || values.gender == false) {
            delete values.gender_male;
            delete values.gender_female;
            delete values.gender_other;
        }
        if (isUndefined(values.age) || values.age == false) {
            values.age_range = ['18', '70'];
        }
        if (isUndefined(values.height) || values.height == false) {
            values.height_range = ['140', '200'];
        }
        if (isUndefined(values.ethnicity) || values.ethnicity == false) {
            for (const [eValueIndex, eValue] of Object.entries(values)) {
                if (eValueIndex.substring(0, 9) == 'ethnicity') {
                    values[eValueIndex] = false;
                }
            }
        }
        if (isUndefined(values.body_type) || values.body_type == false) {
            for (const [eValueIndex, eValue] of Object.entries(values)) {
                if (eValueIndex.substring(0, 9) == 'body_type') {
                    values[eValueIndex] = false;
                }
            }
        }
        if (isUndefined(values.cup) || values.cup == false) {
            for (const [eValueIndex, eValue] of Object.entries(values)) {
                if (eValueIndex.substring(0, 3) == 'cup') {
                    values[eValueIndex] = false;
                }
            }
        }
        if (isUndefined(values.hair_colour) || values.hair_colour == false) {
            for (const [eValueIndex, eValue] of Object.entries(values)) {
                if (eValueIndex.substring(0, 11) == 'hair_colour') {
                    values[eValueIndex] = false;
                }
            }
        }
        if (isUndefined(values.hair_type) || values.hair_type == false) {
            for (const [eValueIndex, eValue] of Object.entries(values)) {
                if (eValueIndex.substring(0, 9) == 'hair_type') {
                    values[eValueIndex] = false;
                }
            }
        }
        if (isUndefined(values.hair_length_male) || values.hair_length_male == false) {
            for (const [eValueIndex, eValue] of Object.entries(values)) {
                if (eValueIndex.substring(0, 16) == 'hair_length_male') {
                    values[eValueIndex] = false;
                }
            }
        }
        if (isUndefined(values.hair_length_female) || values.hair_length_female == false) {
            for (const [eValueIndex, eValue] of Object.entries(values)) {
                if (eValueIndex.substring(0, 18) == 'hair_length_female') {
                    values[eValueIndex] = false;
                }
            }
        }
        if (isUndefined(values.eye_colour) || values.eye_colour == false) {
            for (const [eValueIndex, eValue] of Object.entries(values)) {
                if (eValueIndex.substring(0, 10) == 'eye_colour') {
                    values[eValueIndex] = false;
                }
            }
        }
        if (isUndefined(values.skin_tone) || values.skin_tone == false) {
            for (const [eValueIndex, eValue] of Object.entries(values)) {
                if (eValueIndex.substring(0, 9) == 'skin_tone') {
                    values[eValueIndex] = false;
                }
            }
        }
        dispatch(getJobRequirementRequest({ slug, params: values }));
    };

    const updateJobBasket = (model_id) => {
        dispatch(updateJobBasketRequest({ slug, model_id }));
    };
    const isFetching = useSelector(state => state.job.isFetching);
    const jobRequirement = useSelector(state => state.job.jobRequirement);
    const matchingModels = useSelector(state => state.job.matchingModels);
    return (
        <Row className="search-main-content">
            <Col md={5} className="find-sidebar" xs={0}>
                <StickyBox offsetTop={20} offsetBottom={110}>
                    {!isEmpty(jobRequirement)
                        ? (
                            <StepFindFilter
                                onFilterFieldsChange={onFilterFieldsChange} />
                        )
                        : null}
                </StickyBox>
            </Col>
            <Col md={19} xs={24} className="find-content">
                <div className="search-summary">{matchingModels.length} models match your selection clear filter</div>
                {matchingModels.map((model, modelIndex) => (
                    <ModelBox
                        key={modelIndex}
                        src={`https://testapi.modlapp.com/${model.approved_image || model.featured_image}`}
                        name={model.full_name}
                        onClick={() => updateJobBasket(model.id)}
                        selected={(model.in_basket != '0') || false} />
                ))}
            </Col>
        </Row>
    );
};

export default StepFind;
