import React from 'react';
import './afterHeader.scss';

export default function () {
    const { content } = this.props;
    return (
        <div className="common-header">
            <div className="header-content">
                {content}
            </div>
        </div>
    );
}
