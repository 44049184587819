import React, { useState } from 'react';
import { Col, Modal, Row } from 'antd';
import AwesomeSlider from 'react-awesome-slider';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import history from '../../core/utils/history';
import { UpdatePayment } from '../../core/utils/helpers';
import { confirmJobModelRequest } from '../../core/job/jobActions';
import SelectModelIcon from '../../assets/images/select-model.png';
import DefaultAvatar from '../../assets/images/default-avatar.png';
import { Button, PayPalButton, StripeButton } from '../elements/FormElements';
import * as MenuIcon from '../../assets/images/message.png';
import 'react-awesome-slider/dist/styles.css';
import './modelBoxWithInfo.scss';

const ModelBoxWithInfo = ({
    id,
    src,
    name,
    age,
    gender,
    height,
    status = '',
    reason = '',
    media = [],
    hideMessageButton = false,
    jobId = 0,
    canBookModels = true,
    noOfDays = 0,
    jobFirstDay = null,
    jobFee = 0,
}) => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    const [isModalVisible, setIsModalVisible] = useState(false);
    // const className = `find-image ${type == 'small' ? ' find-image-small' : ''}`;
    const actionIcon = SelectModelIcon;
    const imgSrc = src == null || src == undefined ? DefaultAvatar : src;
    let md = 12;
    let xs = 24;
    let showActionButton = true;
    let modalBoxButton = (
        <Button
            disabled={false}
            type="primary"
            className="button-primary"
            onClick={() => {
                dispatch(confirmJobModelRequest({ slug, model_id: id }));
                setIsModalVisible(false);
            }}
            style={{ position: 'absolute', bottom: 0 }}
            label="Confirm Booking" />
    );
    let boxContent = (
        <>
            <div className="model-info-box-title">{name}</div>
            <div className="model-info-box-content"><strong>Age:</strong> {age}</div>
            <div className="model-info-box-content"><strong>Gender:</strong> {gender}</div>
            <div className="model-info-box-content"><strong>Height:</strong> {height}</div>
            {!hideMessageButton
                ? (
                    <Button
                        disabled={false}
                        type="primary"
                        className="button-primary"
                        onClick={() => setIsModalVisible(true)}
                        style={{ position: 'absolute', bottom: 0 }}
                        label="Confirm Booking" />
                )
                : null}
        </>
    );

    if (status == 'booked') {
        boxContent = (
            <>
                <div className="model-info-box-title">{name}</div>
                <div className="model-info-box-content"><strong>Age:</strong> {age}</div>
                <div className="model-info-box-content"><strong>Gender:</strong> {gender}</div>
                <div className="model-info-box-content"><strong>Height:</strong> {height}</div>
                {!hideMessageButton
                    ? (
                        <Button
                            disabled={false}
                            // type="primary"
                            // className="button-primary"
                            onClick={() => setIsModalVisible(true)}
                            style={{ position: 'absolute', bottom: 0 }}
                            label={<><img src={MenuIcon} /> Message</>} />
                    )
                    : null}
            </>
        );
        modalBoxButton = (
            <Button
                disabled={false}
                // type="primary"
                // className="button-primary"
                onClick={() => history.push(`/messages/${jobId}/${id}`)}
                style={{ position: 'absolute', bottom: 0 }}
                label={<><img src={MenuIcon} /> Message</>} />
        );
    } else if (status == 'discarded') {
        md = 8;
        xs = 24;
        showActionButton = false;
        boxContent = (
            <>
                <div className="model-info-box-content"><strong>Reason:</strong></div>
                <div className="model-info-box-content">{reason}</div>
            </>
        );
        modalBoxButton = null;
    } else if (status == 'awaiting') {
        md = 4;
        xs = 12;
        showActionButton = false;
        boxContent = null;
        modalBoxButton = null;
    }

    modalBoxButton = !hideMessageButton ? modalBoxButton : null;

    const otherImages = media.map(mediaItem => ({ source: mediaItem }));

    const firstName = (name && name.split(' ')[0]) || '';

    return (
        <Col md={md} xs={xs} className={`model-info-box-container ${status == 'awaiting' ? 'small' : ''} ${status == 'discarded' ? 'medium' : ''}`}>
            <div className="model-info-box">
                <img alt={name} src={imgSrc} width="100%" />
                {showActionButton
                    ? (
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setIsModalVisible(true);
                            }}
                            className="action-button">
                            <SearchOutlined
                                style={{ fontSize: '23px', color: '#FFFFFF' }} />
                        </a>
                    )
                    : null}
            </div>
            {status != 'awaiting'
                ? (
                    <div className="model-info-box">
                        {boxContent}
                    </div>
                )
                : null}
            <Modal
                title={null}
                width={880}
                centered
                footer={null}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}>
                {canBookModels
                    ? (
                        <Row style={{ height: '400px' }}>
                            <Col xs={24}
                                md={{
                                    span: 12,
                                    offset: 0,
                                }}>
                                <AwesomeSlider
                                    fillParent
                                    bullets={false}
                                    startup={false}
                                    media={otherImages} />
                            </Col>
                            <Col xs={24}
                                md={{
                                    span: 11,
                                    offset: 1,
                                }}>
                                <div className="model-info-box-title modal-header-title">{firstName}</div>
                                <div className="model-info-box-content"><strong>Age:</strong> {age}</div>
                                <div className="model-info-box-content"><strong>Gender:</strong> {gender}</div>
                                <div className="model-info-box-content"><strong>Height:</strong> {height}</div>
                                {modalBoxButton}
                            </Col>
                        </Row>
                    )
                    : (
                        <div className="pay-modal-box">
                            <Row>
                                <Col xs={24}>
                                    <div className="model-info-box-heading">You have used your pre-paid bookings </div>
                                    <div>Please pay for an additional booking to book this model </div>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '48px' }}>
                                <Col xs={12} style={{ textAlign: 'left' }}>No of job days</Col>
                                <Col xs={12} style={{ textAlign: 'right' }}>{noOfDays}</Col>
                            </Row>
                            <Row style={{ marginTop: '8px' }}>
                                <Col xs={12} style={{ textAlign: 'left' }}>First job day</Col>
                                <Col xs={12} style={{ textAlign: 'right' }}>{jobFirstDay}</Col>
                            </Row>
                            <Row style={{ marginTop: '32px' }}>
                                <Col xs={24} style={{ textAlign: 'left' }}>
                                    <div className="model-info-box-title modal-header-title">Total you will pay</div>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '8px' }}>
                                <Col xs={12} style={{ textAlign: 'left' }}>Basic job fee</Col>
                                <Col xs={12} style={{ textAlign: 'right' }}>£{jobFee}</Col>
                            </Row>
                            <Row style={{ marginTop: '8px' }}>
                                <Col xs={12} style={{ textAlign: 'left' }}>Booking fee (19%)</Col>
                                <Col xs={12} style={{ textAlign: 'right' }}>£{jobFee * 19 / 100}</Col>
                            </Row>
                            <Row style={{ marginTop: '16px', borderTop: '1px solid #CCC', borderBottom: '1px solid #CCC', padding: '16px 0px' }}>
                                <Col xs={12} style={{ textAlign: 'left' }}>
                                    <strong>Total</strong>
                                </Col>
                                <Col xs={12} style={{ textAlign: 'right' }}>£{parseFloat(jobFee) + parseFloat(jobFee * 19 / 100)}</Col>
                            </Row>
                            <Row>
                                <Col xs={24} md={11}>
                                    <PayPalButton
                                        paymentSuccess={UpdatePayment}
                                        amount={parseFloat(jobFee) + parseFloat(jobFee * 19 / 100)} />
                                </Col>
                                <Col xs={24} md={2} style={{ padding: '18px 0px', textAlign: 'center' }}>or</Col>
                                <Col xs={24} md={11}>
                                    <StripeButton
                                        paymentSuccess={(data, details) => UpdatePayment(data, details, slug)}
                                        amount={parseFloat(jobFee) + parseFloat(jobFee * 19 / 100)} />
                                </Col>
                            </Row>
                        </div>
                    )}
            </Modal>
        </Col>
    );
};
export default ModelBoxWithInfo;
