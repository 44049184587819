import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import Layout from '../../components/layout/Layout';
import LoginForm from '../../components/LoginForm';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Breadcrumb from '../../components/layout/Breadcrumb';
import MODLLogo from '../../assets/images/modl.svg';

export default function () {
    const { isFetching } = this.props;
    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];
    const navigation = [{ label: 'Item 1', link: '/abc' }, { label: 'Item 1', link: '/abc' }];

    return (
        <Layout
            className="login"
            title=""
            showHeader={false}
            showFooter={false}
            backLink={null}>
            <Spin spinning={isFetching}>
                <LoginForm />
            </Spin>
        </Layout>
    );
}
