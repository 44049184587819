/* global window:true */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spin, Form, Input, Button, Radio, Row, Col } from 'antd';
import { debounce, isArray, isEmpty, isUndefined } from 'underscore';
import { getJobDetailsRequest } from '../../../core/job/jobActions';
import locationImage from '../../../assets/images/location.png';
import './steps.scss';
import { Checkbox, GoogleLocation } from '../../../components/elements/FormElements';
let typingTimer = null;
const StepJobDetails = ({ form, formErrors, setFormErrors }) => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    const isFetching = useSelector(state => state.job.isFetching);
    const jobDetails = useSelector(state => state.job.jobDetails);
    const jobFurtherDetails = useSelector(state => state.job.jobFurtherDetails);
    const matchingModels = useSelector(state => state.job.matchingModels);
    const selectedModels = matchingModels.filter(matchingModel => matchingModel.in_basket == '1');
    useEffect(() => {
        dispatch(getJobDetailsRequest({ slug }));
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => form.resetFields(), [jobDetails]);
    const onFilterFieldsChange = (changedFields, allFields) => {
        new Promise((resolve, reject) => {
            if (typingTimer != null) {
                clearTimeout(typingTimer);
            }
            let errors = '';
            const filterErrors = allFields.filter(field => field.errors.length > 0);
            filterErrors.forEach(formField => {
                if (formField.errors.length > 0 && errors == '') {
                    errors = formField.errors[0];
                    resolve(errors);
                }
            });
            if (filterErrors.length === 0) {
                resolve(errors);
            }

        }).then(errors => {
            setFormErrors(errors);
            return errors;
        }).then(errors => {
            if (errors == '') {
                const values = [];
                allFields.filter((field) => {
                    if (isArray(field.value)) {
                        return (field.value).length > 0;
                    }
                    return !isUndefined(field.value) && field.value !== false;
                }).forEach((field) => values[field.name[0]] = field.value);
                typingTimer = setTimeout(() => dispatch(getJobDetailsRequest({ slug, params: values })), 3000);
            }
        }).catch(e => console.log('>>>>>>>', e));
    };
    const setLocation = (value) => {
        form.setFieldsValue({ job_location: value });
        form.submit();
    };
    const locationHelp = ''; // <span className="custom-help">2 models in your selection live more than 50 miles from this location</span>;
    const wardrobe_box = !!(jobFurtherDetails && jobFurtherDetails.wardrobe_requirements && !isEmpty(jobFurtherDetails.wardrobe_requirements));
    const [wardrobeBox, setWardrobeBox] = useState(wardrobe_box ? 'yes' : 'no');
    let wordrobeRequirements = jobFurtherDetails && jobFurtherDetails.wardrobe_requirements;
    if (wardrobeBox == 'no') {
        wordrobeRequirements = '';
    }
    const compulsory_box = !!(jobFurtherDetails && jobFurtherDetails.compulsory_requirements && !isEmpty(jobFurtherDetails.compulsory_requirements));
    const [compulsoryBox, setCompulsoryBox] = useState(compulsory_box ? 'yes' : 'no');
    let compulsoryRequirements = jobFurtherDetails && jobFurtherDetails.compulsory_requirements;
    if (compulsoryBox == 'no') {
        compulsoryRequirements = '';
    }
    const specialRequirements = jobFurtherDetails && jobFurtherDetails.special_requirements ? (jobFurtherDetails.special_requirements).split(',') : [];
    const locationValue = jobDetails && jobDetails.location_address;
    return (
        <div className="main-content with-margin-top">
            <Form
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="vertical"
                form={form}
                onFieldsChange={onFilterFieldsChange} // {debounce(onFilterFieldsChange, 3000)}
                initialValues={{
                    layout: 'vertical',
                    job_title: jobDetails && jobDetails.title,
                    job_description: jobDetails && jobDetails.description,
                    job_location: locationValue,
                    contact_telephone: jobDetails && jobDetails.contact_telephone,
                    wardrobe_box: wardrobeBox,
                    wardrobe_requirements: wordrobeRequirements,
                    compulsory_box: compulsoryBox,
                    compulsory_requirements: compulsoryRequirements,
                    special_requirements_commercial: specialRequirements.includes('commercial'),
                    special_requirements_editorial: specialRequirements.includes('editorial'),
                    special_requirements_runway: specialRequirements.includes('runway'),
                    special_requirements_couples: specialRequirements.includes('couples'),
                    'special_requirements_tv-live': specialRequirements.includes('tv-live'),
                    'special_requirements_tv-commercial': specialRequirements.includes('tv-commercial'),
                    special_requirements_catalogue: specialRequirements.includes('catalogue'),
                    special_requirements_underwear: specialRequirements.includes('underwear'),
                    special_requirements_swimwear: specialRequirements.includes('swimwear'),
                    special_requirements_glamour: specialRequirements.includes('glamour'),
                }}>
                <Form.Item label="Job title"
                    name="job_title"
                    rules={[
                        {
                            required: true,
                            message: 'Add a job title',
                        },
                    ]}>
                    <Input placeholder="Job title" />
                </Form.Item>
                <Form.Item name="job_description"
                    label="Job description"
                    rules={[
                        {
                            required: true,
                            message: 'Add job description!',
                        },
                    ]}>
                    <Input.TextArea rows={4} placeholder="Please add description" />
                </Form.Item>
                <Form.Item label="Specific location"
                    name="job_location"
                    help={locationHelp}
                    rules={[
                        {
                            required: true,
                            message: 'Please input job location!',
                        },
                    ]}>
                    <GoogleLocation val={locationValue} onUpdate={setLocation} />
                    {/* <Input placeholder="Specific location" suffix={<img alt="Location" src={locationImage} />} /> */}
                </Form.Item>
                <Form.Item label="Contact telephone number" name="contact_telephone">
                    <Input placeholder="Best contact number for you" />
                </Form.Item>
                <Form.Item label="Are there compulsory Wardrobe Requirements?" name="wardrobe_box">
                    <Radio.Group buttonStyle="solid" onChange={(e) => setWardrobeBox(e.target.value)}>
                        <Radio.Button value="yes">Yes</Radio.Button>
                        <Radio.Button value="no">No</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <div className={wardrobeBox == 'no' ? 'hide-component' : ''}>
                    <Form.Item name="wardrobe_requirements" label="Wardrobe requirements">
                        <Input.TextArea rows={4} placeholder="E.g. “Please bring a pair of blue jeans and a plain white v-neck t-shirt” or “Arrive with natural makeup and hair down”" />
                    </Form.Item>
                </div>
                <Form.Item label="Are there any other compulsory Requirements?" name="compulsory_box">
                    <Radio.Group buttonStyle="solid" onChange={(e) => setCompulsoryBox(e.target.value)}>
                        <Radio.Button value="yes">Yes</Radio.Button>
                        <Radio.Button value="no">No</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <div className={compulsoryBox == 'no' ? 'hide-component' : ''}>
                    <Form.Item name="compulsory_requirements" label="Compulsory requirements">
                        <Input.TextArea rows={4} placeholder="E.g. “Please bring a pair of blue jeans and a plain white v-neck t-shirt” or “Arrive with natural makeup and hair down”" />
                    </Form.Item>
                </div>
                <Form.Item label="Type of job (Optional, select all that apply)">
                    <Row>
                        <Col xs={12} md={8}>
                            <Form.Item name="special_requirements_commercial" valuePropName="checked">
                                <Checkbox label="Commercial" />
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item name="special_requirements_editorial" valuePropName="checked">
                                <Checkbox label="Editorial" />
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item name="special_requirements_runway" valuePropName="checked">
                                <Checkbox label="Runway" />
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item name="special_requirements_couples" valuePropName="checked">
                                <Checkbox label="Couples" />
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item name="special_requirements_tv-live" valuePropName="checked">
                                <Checkbox label="TV (live)" />
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item name="special_requirements_tv-commercial" valuePropName="checked">
                                <Checkbox label="TV (commercial)" />
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item name="special_requirements_catalogue" valuePropName="checked">
                                <Checkbox label="Catalogue" />
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item name="special_requirements_underwear" valuePropName="checked">
                                <Checkbox label="Underwear" />
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item name="special_requirements_swimwear" valuePropName="checked">
                                <Checkbox label="Swimwear" />
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item name="special_requirements_glamour" valuePropName="checked">
                                <Checkbox label="Glamour" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </div>
    );
};

export default StepJobDetails;
