import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { submitJobRequest } from '../job/jobActions';

const sanatizeData = (data) => data.toLowerCase().replaceAll(' ', '_');

const getFieldValue = (field, values) => {
    const fieldValue = values.filter((value, valueIndex) => {
        console.log('>>>>>', valueIndex, value);
        return valueIndex == field;
    });
    console.log('++++++++++', fieldValue, values);
    return (fieldValue && fieldValue[0]) || false;
};

const filterRequirementParams = (key, values) => {

    if (key == 'gender') {
        const gender = getFieldValue('gender', values);
        const gender_male = getFieldValue('gender_male', values);
        const gender_female = getFieldValue('gender_female', values);
        const gender_other = getFieldValue('gender_other', values);
        // if one or more gender values are selected
        if (gender && (gender_male || gender_female || gender_other)) {
            return true;
        }
        return false;
    }
        /* location,
        gender,
        gender_male,
        gender_female,
        gender_other,
        age,
        age_range,
        height,
        height_range,
        ethnicity,
        ethnicity_african,
        ethnicity_asian,
        ethnicity_indian_pakistani,
        ethnicity_caribbean,
        ethnicity_mixed,
        ethnicity_caucasian,
        ethnicity_other,
        waist,
        waist_size_range,
        hips,
        hip_size_range,
        bra,
        bra_size_range,
        shoe,
        shoe_size_range,
        cup_size,
        body_type,
        hair_colour,
        hair_type,
        hair_length_male,
        hair_length_female,
        eye_colour,
        skin_tone, */
};

const UpdatePayment = (data, details = null) => {
    const paymentDetails = {
        payment_number: '',
        notes: '',
        paypal_auth_id: '',
        paypal_capture_id: '',
        paypal_sale_id: '',
        paypal_payer_id: '',
        payment_status: '',
        payment_method: '',
        status: '',
        amount: 0,
    };
    if (details === null) {
        // Stripe
        console.log('::::::: Stripe', JSON.stringify(data));
    } else {
        // Paypal
        paymentDetails.payment_method = 'paypal';
        paymentDetails.amount = details?.purchase_units[0]?.payments?.captures[0]?.amount?.value;
        paymentDetails.paypal_auth_id = details?.id;
        paymentDetails.paypal_capture_id = details?.purchase_units[0]?.payments?.captures[0]?.id;
        paymentDetails.status = details?.status;
        paymentDetails.payment_status = paymentDetails.status;
        paymentDetails.paypal_payer_id = details?.payer?.payer_id;
    }
    return paymentDetails;
};

export {
    sanatizeData,
    filterRequirementParams,
    getFieldValue,
    UpdatePayment,
};
