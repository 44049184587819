import { Record } from 'immutable';
import { findIndex } from 'underscore';

const {
    GET_MESSAGE_LIST_REQUEST,
    GET_MESSAGE_LIST_SUCCESS,
    GET_MESSAGE_LIST_FAILURE,

    GET_SELECTED_CONVERSATION_REQUEST,
    GET_SELECTED_CONVERSATION_SUCCESS,
    GET_SELECTED_CONVERSATION_FAILURE,

    SEND_NEW_MESSAGE_REQUEST,
    SEND_NEW_MESSAGE_SUCCESS,
    SEND_NEW_MESSAGE_FAILURE,

} = require('./messageActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    isConversationFetching: false,
    messageList: [],
    conversation: [],
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function messageReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_MESSAGE_LIST_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);
    case GET_SELECTED_CONVERSATION_REQUEST:
        return state.set('isConversationFetching', true)
            .set('error', null);
    case SEND_NEW_MESSAGE_REQUEST:
        return state.set('isConversationFetching', true);
    case GET_MESSAGE_LIST_SUCCESS:
        return state.set('isFetching', false)
            .set('messageList', payload.data);
    case GET_SELECTED_CONVERSATION_SUCCESS:
    case SEND_NEW_MESSAGE_SUCCESS:
        return state.set('isConversationFetching', false)
            .set('conversation', payload.data);
    case GET_MESSAGE_LIST_FAILURE:
    case SEND_NEW_MESSAGE_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);
    default:
        return state;

    }
}
