import { combineReducers } from 'redux';
import { notification } from 'antd';
import history from './utils/history';
import global from './global/globalReducer';
import user from './user/userReducer';
import job from './job/jobReducer';
import message from './message/messageReducer';

/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const appReducers = combineReducers({
    // ... your other reducers here ...
    global,
    user,
    job,
    message,
});

const rootReducer = (state, action) => {
    // ... your other reducers here ...
    if ((action.type).indexOf('_FAILURE') !== -1) {
        const statusCode = (action && action.payload && action.payload.response && action.payload.response.status) || 404;
        if (statusCode == 401) {
            // Session expired log the user out
            // state.user = null;
            // state.job = null;
            localStorage.removeItem('persist:root');
            localStorage.clear();
            window.location.href = '/';
        }
    }

    return appReducers(state, action);
};

export default rootReducer;
