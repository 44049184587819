import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { SmileTwoTone, HeartTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, PayPalButton, StripeButton } from '../../../components/elements/FormElements';
import history from '../../../core/utils/history';
import { UpdatePayment } from '../../../core/utils/helpers';
import StepFind from './StepFind';
import StepJobDetails from './StepJobDetails';
import * as jobActions from '../../../core/job/jobActions';

class Footer extends Component {

    render() {
        const { params, matchingModels, formErrors, paypalSDKReady, jobFee, basketModels } = this.props;
        console.log('######### paypalSDKReady', paypalSDKReady);
        let step = 'first';
        const slug = params && params.slug;
        const url = `/job/${slug}`;
        let nextUrl = `${url}/second`;
        let prevUrl = '/dashboard';
        if (params && params.step) {
            step = params.step;
        }
        let rightButtonLabel = 'Next';
        let leftButtonLabel = 'Cancel';
        const leftButtonDisabled = false;
        let rightButtonDisabled = false;
        let heading = <>Looks good <CheckCircleTwoTone twoToneColor="#52c41a" /></>;
        let subHeading = '';
        if (step == 'first') {
            // const basketModels = matchingModels.filter((model) => model.in_basket != '0');
            if (basketModels.length < 5) {
                rightButtonDisabled = true;
                heading = 'Shortlist more models';
                subHeading = '';
            }
            if (matchingModels.length >= 20) {
                subHeading = 'Please reduce selected models to fewer than 20';
            }
        } else if (step == 'second') {
            nextUrl = `${url}/third`;
            prevUrl = `${url}/first`;
            leftButtonLabel = 'Previous';
            if (formErrors != '') {
                subHeading = 'Models will see this on their availability request';
            }
        } else if (step == 'third') {
            nextUrl = `${url}/fourth`;
            prevUrl = `${url}/second`;
            leftButtonLabel = 'Previous';
            rightButtonLabel = 'Review';
            if (formErrors != '') {
                heading = 'Specify a job start date';
                subHeading = 'So selected models can check their availability';
            }

        } else if (step == 'fourth') {
            nextUrl = `${url}/review`;
            prevUrl = `${url}/third`;
            rightButtonLabel = 'Review';
            leftButtonLabel = 'Previous';
            heading = 'Review your job information';
            subHeading = 'Selected models will see and consider this information to decide if they are available and want the job.';
        }
        const rightButtonAction = () => history.push(nextUrl);
        const leftButtonAction = () => history.push(prevUrl);
        if (formErrors != '') {
            rightButtonDisabled = true;
            heading = formErrors;
        }


        // console.log('#### >>>> ######', nextUrl, prevUrl);
        return (
            <Row justify="space-around">
                {step != 'fourth'
                    ? (
                        <>
                            <Col xs={0} md={6}>
                                <Button
                                    disabled={leftButtonDisabled}
                                    className="default-button"
                                    onClick={leftButtonAction}
                                    style={{ marginTop: 10 }}
                                    label={leftButtonLabel} />
                            </Col>
                            <Col xs={0} md={12}>
                                <div className="footer-heading">{heading}</div>
                                <div className="footer-sub-heading">{subHeading}</div>
                            </Col>
                            <Col xs={0} md={6}>
                                <Button
                                    disabled={rightButtonDisabled}
                                    type="primary"
                                    className="button-primary"
                                    onClick={rightButtonAction}
                                    style={{ float: 'right', marginTop: 10 }}
                                    label={rightButtonLabel} />
                            </Col>
                        </>
                    )
                    : (
                        <>
                            <Col xs={0} md={4}>
                                <Button
                                    disabled={leftButtonDisabled}
                                    className="default-button"
                                    onClick={leftButtonAction}
                                    style={{ marginTop: 10 }}
                                    label={leftButtonLabel} />
                            </Col>
                            <Col xs={0} md={11} style={{ marginTop: '-10px' }}>
                                <div className="footer-heading">{heading}</div>
                                <div className="footer-sub-heading">{subHeading}</div>
                            </Col>

                            <Col xs={0} md={4}>
                                {paypalSDKReady
                                    ? (
                                        <PayPalButton
                                            onApprove={(data, details) => UpdatePayment(data, details)}
                                            amount={jobFee} />
                                    )
                                    : null}

                                {/* <Button
                            disabled={false}
                            type="primary"
                            className="button-primary btn-paypal"
                            onClick={() => {}}
                            style={{ float: 'right', marginTop: 10 }}
                            label={<>Pay via <img src={PaypalIcon} alt="paypal" style={{ marginTop: '1px' }} /></>} /> */}
                            </Col>
                            <Col xs={0} md={1} style={{ textAlign: 'center', marginTop: '20px' }}>or</Col>
                            <Col xs={0} md={4}>
                                <StripeButton onToken={() => {}} amount={jobFee} />
                            </Col>
                        </>
                    )}

            </Row>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.job.isFetching,
        matchingModels: state.job.matchingModels,
        basketModels: state.job.basketModels,
        shouldPageChange: state.job.shouldPageChange,
        jobFee: state.job.jobFee,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...jobActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
