import React from 'react';
import PropTypes from 'prop-types';

import { CoreRouter, LogoutRoute, DefaultRoute, AuthRoute } from './utils/coreRouter';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Search from '../pages/Search';
import Job from '../pages/Job';
import Messages from '../pages/Messages';
import MyAccount from '../pages/MyAccount';

const Routes = ({ persistor }) => (
    <CoreRouter>
        <LogoutRoute exact path="/logout" persistor={persistor} />

        <DefaultRoute exact path="/" component={Login} />
        <AuthRoute exact path="/dashboard" component={Dashboard} />
        <AuthRoute exact path="/job/:slug" component={Job} />
        <AuthRoute exact path="/job/:slug/:step" component={Search} />
        <AuthRoute exact path="/search/:step?" component={Search} />
        <AuthRoute exact path="/messages/:jobId?/:modelId?" component={Messages} />
        <AuthRoute exact path="/my-account" component={MyAccount} />
    </CoreRouter>
);

Routes.defaultProps = {
    persistor: null,
};

Routes.propTypes = {
    persistor: PropTypes.object,
};

export default Routes;
