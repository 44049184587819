import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DOM from './header';

class Header extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    render() {
        return this.view(this.props);
    }
}

Header.defaultProps = {
    toolbar: [],
    menu: [],
    backLink: null,
    logo: null,
    menuPlacement: 'left',
    menuWidth: 256,
    floating: false,
    beforeHeader: null,
    afterHeader: null,
    title: null,
    menuText: null,
};

Header.propTypes = {
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    toolbar: PropTypes.array,
    menu: PropTypes.array,
    backLink: PropTypes.object,
    logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    menuPlacement: PropTypes.string,
    menuWidth: PropTypes.number,
    floating: PropTypes.bool,
    beforeHeader: PropTypes.object,
    afterHeader: PropTypes.object,
    menuText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isLoggedIn: state.user.isLoggedIn,
        loginUser: state.user.loginUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {

            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Header);
