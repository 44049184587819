import { Component } from 'react';
import PropTypes from 'prop-types';
import DOM from './breadcrumb';

class Breadcrumb extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    render() {
        return this.view(this.props);
    }
}

Breadcrumb.defaultProps = {
    navigation: [],
    separator: '/',
};

Breadcrumb.propTypes = {
    navigation: PropTypes.array,
    separator: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default Breadcrumb;
