import React from 'react';
import { Col, Row, Avatar } from 'antd';
import RobHallmark from '../../../assets/images/rob-hallmark.png';
import './testimonialBox.scss';

const TestimonialBox = () => (
    <div className="testimonial-box">
        <Row style={{ marginBottom: '32px' }}>
            <Col span={24}>
                <h1 className="heading-review-page">What MODL clients say</h1>
            </Col>
        </Row>
        <div className="customer-testimonial">
            Lorem ipsum dolor sit amet, consectetur adipiscing, dolor sit amet, consectetur adipiscing, dolor sit amet, consectetur adipiscing. Dolor sit amet, consectetur adipiscing, dolor sit amet, consectetur adipiscing.
        </div>
        <Row style={{ marginTop: '16px' }}>
            <Col xs={2}>
                <Avatar size={48} src={RobHallmark} />
            </Col>
            <Col xs={22}>
                <div className="testimonial-author ">Rob Hallmark</div>
                <div className="testimonial-position">CEO of Gruhme</div>
            </Col>
        </Row>
    </div>
);

export default TestimonialBox;
