import { Component } from 'react';
import PropTypes from 'prop-types';
import DOM from './footer';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    render() {
        return this.view(this.props);
    }
}

Footer.defaultProps = {
    floating: false,
};

Footer.propTypes = {
    floating: PropTypes.bool,
};

export default Footer;
