import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce, isArray } from 'underscore';
import { Form, Button, Radio, Row, Col } from 'antd';
import { Input, Checkbox, Slider } from '../../../components/elements/FormElements';
import { sanatizeData } from '../../../core/utils/helpers';
import locationImage from '../../../assets/images/location.png';

const { cupSizes } = require('../../../core/constants').default;

export default function ({ onFilterFieldsChange }) {

    const jobRequirement = useSelector((state) => state.job.jobRequirement);
    const jobLocation = useSelector((state) => state.job.jobLocation);
    const {
        gender,
        age,
        height,
        waist_size,
        hip_size,
        bra_size,
        ethnicity,
        cup_size,
        shoe_size,
        body_type,
        hair_colour,
        hair_type,
        hair_length_male,
        hair_length_female,
        eye_colour,
        skin_tone,
    } = jobRequirement;
    const isSelected = (type) => jobRequirement[type] && jobRequirement[type].length > 0;
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState('vertical');
    const [genderBox, setGenderBox] = useState(isSelected('gender'));
    const [ageBox, setAgeBox] = useState(isSelected('age'));
    const [heightBox, setHeightBox] = useState(isSelected('height'));
    const [ethnicityBox, setEthnicityBox] = useState(isSelected('ethnicity'));
    const [waistBox, setWaistBox] = useState(isSelected('waist_size'));
    const [hipsBox, setHipsBox] = useState(isSelected('hip_size'));
    const [braBox, setBraBox] = useState(isSelected('bra'));
    const [shoeBox, setShoeBox] = useState(isSelected('shoe_size'));
    const [cupSizeBox, setCupSizeBox] = useState(isSelected('cup_size'));
    const [bodyTypeBox, setBodyTypeBox] = useState(isSelected('body_type'));
    const [hairColourBox, setHairColourBox] = useState(isSelected('hair_colour'));
    const [hairTypeBox, setHairTypeBox] = useState(isSelected('hair_type'));
    const [hairLengthMaleBox, setHairLengthMaleBox] = useState(isSelected('hair_length_male'));
    const [hairLengthFemaleBox, setHairLengthFemaleBox] = useState(isSelected('hair_length_female'));
    const [eyeColourBox, setEyeColourBox] = useState(isSelected('eye_colour'));
    const [skinToneBox, setSkinToneBox] = useState(isSelected('skin_tone'));
    // useEffect(() => form.resetFields(), [jobRequirement]);
    const customTipFormatter = (value) => {
        // TRUNC(B2/2.54/12)&"' "&ROUND(MOD(B2/2.54,12),0)&""""
        // const cm2ft = `${(value/2.54/12)} ${Math.round(value/2.54)}`;
        const realFeet = ((value * 0.393700) / 12);
        const feet = Math.floor(realFeet);
        const inches = Math.round((realFeet - feet) * 12);
        return (
            <>
                <div className="main-label">{feet}ft {inches}</div>
                <div className="sub-label">{value} cm</div>
            </>
        );
    };

    const cupSizesInitialValues = {};
    if (cup_size) {
        cupSizes.forEach((cupSize, cupSizeIndex) => {
            const val = (cupSizeIndex + 1) * 10;
            const key = `cup_size_${cupSize}`;
            cupSizesInitialValues[key] = cup_size.includes(val.toString());
        });
    }

    const bodyTypes = [
        'Petite', 'Tall', 'Slim', 'Curvy', 'Athletic', 'Muscular', 'Overweight', 'Plus-sized',
    ];

    const bodyTypesInitialValues = {};
    if (body_type) {
        bodyTypes.forEach((bodyType, bodyTypeIndex) => {
            const val = bodyTypeIndex + 10;
            const key = `body_type_${bodyType}`;
            bodyTypesInitialValues[key] = body_type.includes(val.toString());
        });
    }

    const hairColours = [
        'Black', 'Dark brown', 'Mid brown', 'Light brown', 'Auburn', 'Ginger',
        'Dark blonde', 'Blonde', 'Red', 'Platinum', 'Grey', 'Salt and pepper', 'Auburn',
    ];

    const hairColoursInitialValues = {};
    if (hair_colour) {
        hairColours.forEach((hairColour, hairColourIndex) => {
            const val = hairColourIndex + 10;
            const key = `hair_colour_${hairColour}`;
            hairColoursInitialValues[key] = hair_colour.includes(val.toString());
        });
    }

    const hairTypes = [
        'Straight', 'Wavy', 'Curly', 'Afro', 'Corn rows', 'Dreadlocks',
    ];

    const hairTypesInitialValues = {};
    if (hair_type) {
        hairTypes.forEach((hairType, hairTypeIndex) => {
            const val = hairTypeIndex + 10;
            const key = `hair_type_${hairType}`;
            hairTypesInitialValues[key] = hair_type.includes(val.toString());
        });
    }

    const hairLengthsMale = [
        'Shaved/bald', 'Short', 'Medium', 'Long',
    ];

    const hairLengthsMaleInitialValues = {};
    if (hair_length_male) {
        hairLengthsMale.forEach((hairLengthMale, hairLengthMaleIndex) => {
            const val = hairLengthMaleIndex + 10;
            const key = `hair_type_${hairLengthMale}`;
            hairLengthsMaleInitialValues[key] = hair_length_male.includes(val.toString());
        });
    }

    const hairLengthsFemale = [
        'Shaved', 'Very short', 'Short', 'Collar length', 'Bob', 'Shoulder length (between shoulder and elbow)',
        'Long (between shoulder and elbow)', 'Very long (elbow and below)',
    ];

    const hairLengthsFemaleInitialValues = {};
    if (hair_length_female) {
        hairLengthsFemale.forEach((hairLengthFemale, hairLengthFemaleIndex) => {
            const val = hairLengthFemaleIndex + 10;
            const key = `hair_type_${hairLengthFemale}`;
            hairLengthsFemaleInitialValues[key] = hair_length_female.includes(val.toString());
        });
    }

    const eyeColours = [
        'Dark brown', 'Brown', 'Hazel', 'Green', 'Blue',
        'Grey', 'Red', 'Heterochromia iridum',
    ];

    const eyeColoursInitialValues = {};
    if (eye_colour) {
        eyeColours.forEach((eyeColour, eyeColourIndex) => {
            const val = eyeColourIndex + 10;
            const key = `eye_colour_${eyeColour}`;
            eyeColoursInitialValues[key] = eye_colour.includes(val.toString());
        });
    }

    const skinTones = [
        'Pale', 'Fair', 'Medium', 'Beige', 'Tanned', 'Olive',
        'Light brown', 'Medium brown', 'Dark brown', 'Very dark brown',
    ];

    const skinTonesInitialValues = {};
    if (skin_tone) {
        skinTones.forEach((skinTone, skinToneIndex) => {
            const val = skinToneIndex + 10;
            const key = `eye_colour_${skinTone}`;
            skinTonesInitialValues[key] = skin_tone.includes(val.toString());
        });
    }
    const cWSize = waist_size || ['28', '48'];
    console.log('>>>>>>>', cWSize);
    return (
        <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout={formLayout}
            form={form}
            onFieldsChange={debounce(onFilterFieldsChange, 800)}
            initialValues={{
                location: jobLocation,
                gender: gender.length > 0,
                gender_male: gender.includes('male'),
                gender_female: gender.includes('female'),
                gender_other: gender.includes('other'),
                age: age.length > 0,
                age_range: age.length > 0 ? age : ['18', '71'],
                height: height.length > 0,
                height_range: height.length > 0 ? height : ['140', '200'],
                ethnicity: ethnicity.length > 0,
                ethnicity_african: ethnicity.includes('african'),
                ethnicity_asian: ethnicity.includes('asian'),
                ethnicity_indian: ethnicity.includes('indian'),
                ethnicity_caribbean: ethnicity.includes('caribbean'),
                ethnicity_mixed: ethnicity.includes('mixed'),
                ethnicity_white: ethnicity.includes('white'),
                ethnicity_other: ethnicity.includes('other'),
                waist: waist_size.length > 0,
                waist_size_range: waist_size.length > 0 ? waist_size : ['28', '48'],
                hips: hip_size.length > 0,
                hip_size_range: hip_size.length > 0 ? hip_size : ['28', '48'],
                bra: bra_size.length > 0,
                bra_size_range: bra_size.length > 0 ? bra_size : ['28', '48'],
                shoe: shoe_size.length > 0,
                shoe_size_range: shoe_size.length > 0 ? shoe_size : ['3', '15'],
                cup: cup_size.length > 0,
                body_type: body_type.length > 0,
                hair_colour: hair_colour.length > 0,
                hair_type: hair_type.length > 0,
                hair_length_male: hair_length_male.length > 0,
                hair_length_female: hair_length_female.length > 0,
                eye_colour: eye_colour.length > 0,
                skin_tone: skin_tone.length > 0,
                ...cupSizesInitialValues,
            }}>
            <Form.Item label="Job location" name="location">
                <Input placeholder="Job location" suffix={<img alt="Location" src={locationImage} />} />
            </Form.Item>
            <Form.Item name="gender" valuePropName="checked">
                <Checkbox label="GENDER"
                    onChange={(e) => {
                        console.log('>>>>>>> gender', e.target.checked);
                        if (!e.target.checked) {
                            form.setFieldsValue({
                                gender_male: false,
                                gender_female: false,
                                gender_other: false,
                            });
                        }
                        setGenderBox(e.target.checked);
                    }} />
            </Form.Item>
            <Row className={!genderBox ? 'hide-component' : ''}>
                <Col xs={{ span: 8, offset: 4 }}>
                    <Form.Item name="gender_male" valuePropName="checked">
                        <Checkbox label="Male" />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 12 }}>
                    <Form.Item name="gender_female" valuePropName="checked">
                        <Checkbox label="Female" />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 20, offset: 4 }}>
                    <Form.Item name="gender_other" valuePropName="checked">
                        <Checkbox label="Other" />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item name="age" valuePropName="checked">
                <Checkbox label="AGE"
                    onChange={(e) => {
                        console.log('>>>>>>> age', e.target.checked);
                        if (!e.target.checked) {
                            form.setFieldsValue({
                                age_range: ['18', '70'],
                            });
                        }
                        setAgeBox(e.target.checked);
                    }} />
            </Form.Item>
            <Row className={!ageBox ? 'hide-component' : ''}>
                <Col xs={{ span: 20, offset: 4 }}>
                    <Form.Item>
                        <Form.Item name="age_range">
                            <Slider
                                min={18}
                                max={71}
                                getTooltipPopupContainer={() => document.getElementById('age_range_tooltip')}
                                range={{ draggableTrack: true }}
                                tooltipPlacement="bottom"
                                tooltipVisible={ageBox} />
                        </Form.Item>
                        <div id="age_range_tooltip" />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item name="height" valuePropName="checked">
                <Checkbox label="Height" onChange={(e) => setHeightBox(e.target.checked)} />
            </Form.Item>
            <Row className={!heightBox ? 'height-slider hide-component' : 'height-slider'}>
                <Col xs={{ span: 20, offset: 4 }}>
                    <Form.Item>
                        <Form.Item name="height_range">
                            <Slider
                                min={140}
                                max={200}
                                tipFormatter={customTipFormatter}
                                range={{ draggableTrack: true }}
                                getTooltipPopupContainer={() => document.getElementById('height_range_tooltip')}
                                tooltipPlacement="bottom"
                                tooltipVisible={heightBox} />
                        </Form.Item>
                        <div id="height_range_tooltip" />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item name="ethnicity" valuePropName="checked">
                <Checkbox label="ETHNICITY" onChange={(e) => {
                    setEthnicityBox(e.target.checked);
                    if (!e.target.checked) {
                        form.setFieldsValue({
                            ethnicity_african: false,
                            ethnicity_asian: false,
                            ethnicity_caribbean: false,
                            ethnicity_mixed: false,
                            ethnicity_other: false,
                            ethnicity_indian: false,
                            ethnicity_white: false,
                        });
                    }
                }} />
            </Form.Item>
            <Row className={!ethnicityBox ? 'hide-component' : ''}>
                <Col xs={{ span: 20, offset: 4 }}>
                    <Form.Item name="ethnicity_african" valuePropName="checked">
                        <Checkbox label="African" />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 20, offset: 4 }}>
                    <Form.Item name="ethnicity_asian" valuePropName="checked">
                        <Checkbox label="Asian" />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 20, offset: 4 }}>
                    <Form.Item name="ethnicity_indian" valuePropName="checked">
                        <Checkbox label="Indian / Pakistani" />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 20, offset: 4 }}>
                    <Form.Item name="ethnicity_caribbean" valuePropName="checked">
                        <Checkbox label="Caribbean" />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 20, offset: 4 }}>
                    <Form.Item name="ethnicity_mixed" valuePropName="checked">
                        <Checkbox label="Mixed ethnic groups" />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 20, offset: 4 }}>
                    <Form.Item name="ethnicity_white" valuePropName="checked">
                        <Checkbox label="Caucasian" />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 20, offset: 4 }}>
                    <Form.Item name="ethnicity_other" valuePropName="checked">
                        <Checkbox label="Other ethnic group" />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item name="waist" valuePropName="checked">
                <Checkbox label="Waist Size (inches)" onChange={(e) => setWaistBox(e.target.checked)} />
            </Form.Item>
            <Row className={!waistBox ? 'height-slider hide-component' : 'height-slider'}>
                <Col xs={{ span: 20, offset: 4 }}>
                    <Form.Item>
                        <Form.Item name="waist_size_range">
                            <Slider
                                min={28}
                                max={48}
                                // tipFormatter={customTipFormatter}
                                range={{ draggableTrack: true }}
                                getTooltipPopupContainer={() => document.getElementById('waist_size_range_tooltip')}
                                tooltipPlacement="bottom"
                                tooltipVisible={waistBox} />
                        </Form.Item>
                        <div id="waist_size_range_tooltip" />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item name="hips" valuePropName="checked">
                <Checkbox label="Hips (inches)" onChange={(e) => setHipsBox(e.target.checked)} />
            </Form.Item>
            <Row className={!hipsBox ? 'height-slider hide-component' : 'height-slider'}>
                <Col xs={{ span: 20, offset: 4 }}>
                    <Form.Item>
                        <Form.Item name="hip_size_range">
                            <Slider
                                min={28}
                                max={48}
                                // tipFormatter={customTipFormatter}
                                range={{ draggableTrack: true }}
                                getTooltipPopupContainer={() => document.getElementById('hip_size_range_tooltip')}
                                tooltipPlacement="bottom"
                                tooltipVisible={hipsBox} />
                        </Form.Item>
                        <div id="hip_size_range_tooltip" />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item name="bra" valuePropName="checked">
                <Checkbox label="Bra Size" onChange={(e) => setBraBox(e.target.checked)} />
            </Form.Item>
            <Row className={!braBox ? 'height-slider hide-component' : 'height-slider'}>
                <Col xs={{ span: 20, offset: 4 }}>
                    <Form.Item>
                        <Form.Item name="bra_size_range">
                            <Slider
                                min={28}
                                max={48}
                                // tipFormatter={customTipFormatter}
                                range={{ draggableTrack: true }}
                                getTooltipPopupContainer={() => document.getElementById('bra_size_range_tooltip')}
                                tooltipPlacement="bottom"
                                tooltipVisible={braBox} />
                        </Form.Item>
                        <div id="bra_size_range_tooltip" />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item name="cup" valuePropName="checked">
                <Checkbox label="Cup Size" onChange={(e) => setCupSizeBox(e.target.checked)} />
            </Form.Item>
            <Row className={!cupSizeBox ? 'hide-component' : ''}>
                {cupSizes.map((cupSize, cupSizeIndex) => {
                    const xs = { span: 12 };
                    if ((cupSizeIndex + 1) % 2 === 1) {
                        xs.span = 8;
                        xs.offset = 4;
                    }
                    return (
                        <Col xs={xs} key={`cup-sizes-${cupSizeIndex}`}>
                            <Form.Item name={`cup_size_${cupSize}`} valuePropName="checked">
                                <Checkbox label={cupSize} />
                            </Form.Item>
                        </Col>
                    );
                })}
            </Row>

            <Form.Item name="shoe" valuePropName="checked">
                <Checkbox label="Shoe Size" onChange={(e) => setShoeBox(e.target.checked)} />
            </Form.Item>
            <Row className={!shoeBox ? 'height-slider hide-component' : 'height-slider'}>
                <Col xs={{ span: 20, offset: 4 }}>
                    <Form.Item>
                        <Form.Item name="shoe_size_range">
                            <Slider
                                min={3}
                                max={15}
                                // tipFormatter={customTipFormatter}
                                range={{ draggableTrack: true }}
                                getTooltipPopupContainer={() => document.getElementById('shoe_size_range_tooltip')}
                                tooltipPlacement="bottom"
                                tooltipVisible={shoeBox} />
                        </Form.Item>
                        <div id="shoe_size_range_tooltip" />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item name="body_type" valuePropName="checked">
                <Checkbox label="Body Type" onChange={(e) => setBodyTypeBox(e.target.checked)} />
            </Form.Item>
            <Row className={!bodyTypeBox ? 'hide-component' : ''}>
                {bodyTypes.map((bodyType, bodyTypeIndex) => (
                    <Col xs={{ span: 20, offset: 4 }} key={`body_type-${bodyTypeIndex}`}>
                        <Form.Item name={`body_type_${bodyType}`} valuePropName="checked">
                            <Checkbox label={bodyType} />
                        </Form.Item>
                    </Col>
                ))}
            </Row>

            <Form.Item name="hair_colour" valuePropName="checked">
                <Checkbox label="Hair Colour" onChange={(e) => setHairColourBox(e.target.checked)} />
            </Form.Item>
            <Row className={!hairColourBox ? 'hide-component' : ''}>
                {hairColours.map((hairColour, hairColourIndex) => (
                    <Col xs={{ span: 20, offset: 4 }} key={`hair_colour-${hairColourIndex}`}>
                        <Form.Item name={`hair_colour_${hairColour}`} valuePropName="checked">
                            <Checkbox label={hairColour} />
                        </Form.Item>
                    </Col>
                ))}
            </Row>

            <Form.Item name="hair_type" valuePropName="checked">
                <Checkbox label="Hair Type" onChange={(e) => setHairTypeBox(e.target.checked)} />
            </Form.Item>
            <Row className={!hairTypeBox ? 'hide-component' : ''}>
                {hairTypes.map((hairType, hairTypeIndex) => (
                    <Col xs={{ span: 20, offset: 4 }} key={`hair_colour-${hairTypeIndex}`}>
                        <Form.Item name={`hair_type_${hairType}`} valuePropName="checked">
                            <Checkbox label={hairType} />
                        </Form.Item>
                    </Col>
                ))}
            </Row>

            <Form.Item name="hair_length_male" valuePropName="checked">
                <Checkbox label="Hair Lenght Male" onChange={(e) => setHairLengthMaleBox(e.target.checked)} />
            </Form.Item>
            <Row className={!hairLengthMaleBox ? 'hide-component' : ''}>
                {hairLengthsMale.map((hairLengthMale, hairLengthMaleIndex) => (
                    <Col xs={{ span: 20, offset: 4 }} key={`hair_length_male-${hairLengthMaleIndex}`}>
                        <Form.Item name={`hair_length_male_${hairLengthMale}`} valuePropName="checked">
                            <Checkbox label={hairLengthMale} />
                        </Form.Item>
                    </Col>
                ))}
            </Row>

            <Form.Item name="hair_length_female" valuePropName="checked">
                <Checkbox label="Hair Length Female" onChange={(e) => setHairLengthFemaleBox(e.target.checked)} />
            </Form.Item>
            <Row className={!hairLengthFemaleBox ? 'hide-component' : ''}>
                {hairLengthsFemale.map((hairLengthFemale, hairLengthFemaleIndex) => (
                    <Col xs={{ span: 20, offset: 4 }} key={`hair_length_female-${hairLengthFemaleIndex}`}>
                        <Form.Item name={`hair_length_female_${hairLengthFemale}`} valuePropName="checked">
                            <Checkbox label={hairLengthFemale} />
                        </Form.Item>
                    </Col>
                ))}
            </Row>

            <Form.Item name="eye_colour" valuePropName="checked">
                <Checkbox label="Eye Colour" onChange={(e) => setEyeColourBox(e.target.checked)} />
            </Form.Item>
            <Row className={!eyeColourBox ? 'hide-component' : ''}>
                {eyeColours.map((eyeColour, eyeColourIndex) => (
                    <Col xs={{ span: 20, offset: 4 }} key={`eye-colour-${eyeColourIndex}`}>
                        <Form.Item name={`eye_colour_${sanatizeData(eyeColour)}`} valuePropName="checked">
                            <Checkbox label={eyeColour} />
                        </Form.Item>
                    </Col>
                ))}
            </Row>

            <Form.Item name="skin_tone" valuePropName="checked">
                <Checkbox label="Skin Tone" onChange={(e) => setSkinToneBox(e.target.checked)} />
            </Form.Item>
            <Row className={!skinToneBox ? 'hide-component' : ''}>
                {skinTones.map((skinTone, skinToneIndex) => (
                    <Col xs={{ span: 20, offset: 4 }} key={`skin-tone-${skinToneIndex}`}>
                        <Form.Item name={`skin_tone_${skinTone}`} valuePropName="checked">
                            <Checkbox label={skinTone} />
                        </Form.Item>
                    </Col>
                ))}
            </Row>

            {/* <Form.Item name={['user', 'introduction']} label="Job description">
            <Input.TextArea rows={4} placeholder="Please add description" />
        </Form.Item>
        <Form.Item label="Specific location" help={locationHelp}>
            <Input placeholder="input placeholder" suffix={<img src={locationImage} />} />
        </Form.Item>
        <Form.Item label="Contact telephone number">
            <Input placeholder="Best contact number for you" />
        </Form.Item>
        <Form.Item label="Are there compulsory Wardrobe Requiements?">
            <Radio.Group buttonStyle="solid">
                <Radio.Button value="a">Yes</Radio.Button>
                <Radio.Button value="b">No</Radio.Button>
            </Radio.Group>
        </Form.Item>

        <Form.Item>
            <Button type="primary">Submit</Button>
        </Form.Item> */}
            <div style={{ marginBottom: '50px' }} />

        </Form>
    );
}
