import React, { useEffect, useState } from 'react';
import { Alert, Col, Row } from 'antd';
import moment from 'moment';
import { LeftOutlined } from '@ant-design/icons';
import FlipCountdown from '@rumess/react-flip-countdown';
import ModelBoxWithInfo from '../../../components/ModelBoxWithInfo';
import history from '../../../core/utils/history';

const ActiveHeader = ({ completeJob }) => {
    const noOfModels = completeJob?.job_requirement?.no_of_models || 1;
    const approvedDate = completeJob?.approved_on;
    const cancellationDate = completeJob?.cancellation_date;

    const bookedModels = completeJob?.models?.selected || [];
    const pendingModels = completeJob?.models?.pending || [];
    const discardedModels = completeJob?.models?.discarded || [];
    const awaitingModels = completeJob?.models?.awaiting || [];
    const confirmedModels = completeJob?.models?.confirmed || [];

    const selectedBookedModels = (bookedModels.length + confirmedModels.length);
    console.log('>>>>>>>>>>>>>', bookedModels.length, confirmedModels.length, noOfModels);

    const allModelsReplied = (awaitingModels.length + pendingModels.length) == 0;
    const combinedBookedModels = bookedModels.length + confirmedModels.length;
    const [timer, setTimer] = useState('');
    const [timerMessage, setTimerMessage] = useState('');

    const calcDuration = (approvedTime, cancellationTime) => {
        const now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss'); // today's date
        const durationModels = moment.duration(moment(approvedTime, 'YYYY-MM-DD HH:mm:ss').add(48, 'hours').diff(now));
        if (durationModels > 0 && (pendingModels.length > 0)) {
            // Still waiting for models to reply
            const modelDays = durationModels.get('days');
            const modelHours = durationModels.get('hours');
            const modelMins = durationModels.get('minutes');
            const modelSeconds = durationModels.get('seconds');
            let timeString = '';
            if (modelDays > 0) {
                timeString += `${modelDays} days `;
            }
            if (modelHours > 0) {
                timeString += `${modelHours} hours `;
            }
            if (modelMins > 0) {
                timeString += `${modelMins} minutes `;
            }
            if (modelSeconds > 0 && timeString == '') {
                timeString += `${modelSeconds} seconds`;
            }
            const timeStringMsg = timeString != '' ? 'remaining for models to respond to your availability request' : '';
            setTimer(timeString);
            setTimerMessage(timeStringMsg);
        } else if (selectedBookedModels < noOfModels) {
            const durationBooker = moment.duration(moment(cancellationTime, 'YYYY-MM-DD').diff(now));
            if (durationBooker > 0) {
                const bookerDays = durationBooker.get('days');
                const bookerHours = durationBooker.get('hours');
                const bookerMins = durationBooker.get('minutes');
                const bookerSeconds = durationBooker.get('seconds');
                let bookTimeString = '';
                if (bookerDays > 0) {
                    bookTimeString += `${bookerDays} days `;
                }
                if (bookerHours > 0) {
                    bookTimeString += `${bookerHours} hours `;
                }
                if (bookerMins > 0) {
                    bookTimeString += `${bookerMins} minutes `;
                }
                if (bookerSeconds > 0 && bookTimeString == '') {
                    bookTimeString += `${bookerSeconds} seconds`;
                }
                const bookTimeStringMsg = bookTimeString != '' ? 'remaining for you to confirm your models' : '';
                setTimer(bookTimeString);
                setTimerMessage(bookTimeStringMsg);
            }
        }

    };
    setInterval(() => calcDuration(approvedDate, cancellationDate), 1000);

    /* const now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss'); // today's date
    const durationModels = moment.duration(moment(approvedDate, 'YYYY-MM-DD HH:mm:ss').add(48, 'hours').diff(now));
    let endTime = moment(approvedDate, 'YYYY-MM-DD HH:mm:ss').add(48, 'hours').format('YYYY-MM-DD HH:mm:ss');
    let timerMessage = 'remaining for models to respond to your availability request';
    if (durationModels <= 0) {
        endTime = moment(cancellationDate, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss');
        timerMessage = 'remaining for you to confirm your models';
    } */

    return (
        <>
            {timerMessage != '' ?
                <Row gutter={[3, 3]}>
                    <Col md={24} xs={0}>
                        <div className="heading-sub"><span className="heading-main">{timer}</span> {timerMessage}</div>
                        <div className="heading-content">After which we will automatically release unbooked models and refund you any unallocated fees</div>
                    </Col>
                </Row>
                :
                null
            }
            <div className="booking-allocation">
                <div className="heading-title">{combinedBookedModels} of {noOfModels}</div>
                <div className="heading-content">Pre-paid bookings allocated</div>
            </div>
        </>
    );
};

const ActiveBackLink = ({ title }) => (
    <Row align="middle">
        <Col xs={24} md={2}>
            <LeftOutlined style={{ fontSize: '24px' }} onClick={() => history.push('/dashboard')} />
        </Col>
        <Col xs={0} md={22}>
            <div className="back-link-header">Confirm Models</div>
            <div className="back-link-content">{title}</div>
        </Col>
    </Row>
);

const ActiveStatus = ({ completeJob }) => {
    const bookedModels = completeJob?.models?.selected || [];
    const pendingModels = completeJob?.models?.pending || [];
    const discardedModels = completeJob?.models?.discarded || [];
    const awaitingModels = completeJob?.models?.awaiting || [];
    const confirmedModels = completeJob?.models?.confirmed || [];
    const combinedBookedModels = bookedModels.length + confirmedModels.length;
    const noOfModels = completeJob?.job_requirement?.no_of_models || 1;
    const canBookModels = combinedBookedModels < noOfModels;
    const noOfDays = completeJob?.no_of_days;
    const jobFirstDay = completeJob && completeJob.days && completeJob.days[0] && completeJob.days[0].date ? moment(completeJob.days[0].date, 'YYYY-MM-DD').format('DD/MM/YYYY') : null;
    const jobFee = completeJob?.job_fee;
    return (
        <>
            <>
                {bookedModels.length > 0 ?
                    <div className="main-content" style={{ marginBottom: '47px' }}>
                        <Row>
                            <Col span={24}>
                                <h1 className="heading-review-page">Models Booked ({bookedModels.length})</h1>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '22px' }}>
                            {bookedModels.map((bookedModel, bookedModelIndex) => (
                                <ModelBoxWithInfo
                                    key={`booked-model-${bookedModelIndex}`}
                                    jobFee={jobFee}
                                    canBookModels={canBookModels}
                                    noOfDays={noOfDays}
                                    jobFirstDay={jobFirstDay}
                                    id={bookedModel.id}
                                    name={bookedModel.full_name}
                                    age={bookedModel.age}
                                    gender={bookedModel.gender}
                                    height={bookedModel.height}
                                    src={bookedModel.image}
                                    media={bookedModel.other_image}
                                    status="booked" />
                            ))}
                        </Row>
                    </div>
                    :
                    null
                }
            </>
            <>
                {confirmedModels.length > 0 ?
                    <div className="main-content" style={{ marginBottom: '47px' }}>
                        <Row>
                            <Col span={24}>
                                <h1 className="heading-review-page">Waiting For Booking Confirmation ({confirmedModels.length})</h1>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '22px' }}>
                            {confirmedModels.map((confirmedModel, confirmedModelIndex) => (
                                <ModelBoxWithInfo
                                    key={`confirmed-model-${confirmedModelIndex}`}
                                    jobFee={jobFee}
                                    canBookModels={canBookModels}
                                    noOfDays={noOfDays}
                                    jobFirstDay={jobFirstDay}
                                    hideMessageButton
                                    id={confirmedModel.id}
                                    name={confirmedModel.full_name}
                                    age={confirmedModel.age}
                                    gender={confirmedModel.gender}
                                    height={confirmedModel.height}
                                    src={confirmedModel.image}
                                    media={confirmedModel.other_image}
                                    status="confirmed" />
                            ))}
                        </Row>
                    </div>
                    :
                    null
                }
            </>

            <div className="main-content" style={{ marginBottom: '47px' }}>
                <Row>
                    <Col span={24}>
                        <h1 className="heading-review-page">Available Models ({awaitingModels.length})</h1>
                    </Col>
                </Row>
                {awaitingModels.length > 0
                    ? (
                        <>
                            <Row>
                                <Col span={24}>
                                    The following models have confirmed their availability
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '22px' }} gutter={[0, 32]}>
                                {awaitingModels.map((awaitingModel, awaitingModelIndex) => (
                                    <ModelBoxWithInfo
                                        key={`awaiting-model-${awaitingModelIndex}`}
                                        jobFee={jobFee}
                                        canBookModels={canBookModels}
                                        noOfDays={noOfDays}
                                        jobFirstDay={jobFirstDay}
                                        id={awaitingModel.id}
                                        name={awaitingModel.full_name}
                                        age={awaitingModel.age}
                                        gender={awaitingModel.gender}
                                        height={awaitingModel.height}
                                        media={awaitingModel.other_image}
                                        src={awaitingModel.image} />
                                ))}
                            </Row>
                        </>
                    )
                    : (
                        <Alert
                            message="Currently waiting for model responses (check back soon)"
                            description="We are still waiting for model(s) to respond to your job availability request. As responses arrive you will see them here if you refresh the page."
                            type="info"
                            showIcon />
                    )}

            </div>

            <div className="main-content" style={{ marginBottom: '47px' }}>
                <Row>
                    <Col span={24}>
                        <h1 className="heading-review-page">Models that declined ({discardedModels.length})</h1>
                    </Col>
                </Row>
                <Row style={{ marginTop: '22px' }} gutter={[0, 32]}>
                    {discardedModels.map((discardedModel, discardedModelIndex) => (
                        <ModelBoxWithInfo
                            key={`pdiscarded-model-${discardedModelIndex}`}
                            jobFee={jobFee}
                            canBookModels={canBookModels}
                            id={discardedModel.id}
                            src={discardedModel.image}
                            media={discardedModel.other_image}
                            reason={discardedModel.reason}
                            status="discarded" />
                    ))}
                </Row>
            </div>

            <div className="main-content" style={{ marginBottom: '47px' }}>
                <Row>
                    <Col span={24}>
                        <h1 className="heading-review-page">Models pending a response ({pendingModels.length})</h1>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ marginBottom: '8px' }}>
                        Models have up to 2 days from the date the job was posted to confirm availability.
                    </Col>
                    <Col span={24}>
                        Some models choose may not reply to the job at all if they are unavailable or if the job fees are too low.
                    </Col>
                </Row>
                <Row style={{ marginTop: '22px' }} gutter={[0, 32]}>
                    {pendingModels.map((pendingModel, pendingModelIndex) => (
                        <ModelBoxWithInfo
                            key={`pending-model-${pendingModelIndex}`}
                            jobFee={jobFee}
                            canBookModels={canBookModels}
                            id={pendingModel.id}
                            src={pendingModel.image}
                            status="awaiting" />
                    ))}
                </Row>
            </div>
        </>
    );
};

export {
    ActiveBackLink,
    ActiveStatus,
    ActiveHeader,
};
