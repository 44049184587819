import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    DELETE_TOKEN_REQUEST: null,
    DELETE_TOKEN_SUCCESS: null,

    SAVE_TOKEN_REQUEST: null,
    SAVE_TOKEN_SUCCESS: null,

    USER_LOGIN_REQUEST: null,
    USER_LOGIN_SUCCESS: null,
    USER_LOGIN_FAILURE: null,

    LOGOUT_REQUEST: null,
    LOGOUT_SUCCESS: null,
    LOGOUT_FAILURE: null,

    MY_ACCOUNT_REQUEST: null,
    MY_ACCOUNT_SUCCESS: null,
    MY_ACCOUNT_FAILURE: null,

    PASSWORD_RESET_REQUEST: null,
    PASSWORD_RESET_FAILURE: null,
    PASSWORD_RESET_SUCCESS: null,
});

export const {
    deleteTokenRequest,
    deleteTokenSuccess,
} = createActions(
    constants.DELETE_TOKEN_REQUEST,
    constants.DELETE_TOKEN_SUCCESS,
);

export const {
    saveTokenRequest,
    saveTokenSuccess,
} = createActions(
    constants.SAVE_TOKEN_REQUEST,
    constants.SAVE_TOKEN_SUCCESS,
);

export const {
    userLoginRequest,
    userLoginSuccess,
    userLoginFailure,
} = createActions(
    constants.USER_LOGIN_REQUEST,
    constants.USER_LOGIN_SUCCESS,
    constants.USER_LOGIN_FAILURE,
);

export const {
    logoutRequest,
    logoutSuccess,
    logoutFailure,
} = createActions(
    constants.LOGOUT_REQUEST,
    constants.LOGOUT_SUCCESS,
    constants.LOGOUT_FAILURE,
);

export const {
    myAccountRequest,
    myAccountSuccess,
    myAccountFailure,
} = createActions(
    constants.MY_ACCOUNT_REQUEST,
    constants.MY_ACCOUNT_SUCCESS,
    constants.MY_ACCOUNT_FAILURE,
);

export const {
    passwordResetRequest,
    passwordResetSuccess,
    passwordResetFailure,
} = createActions(
    constants.PASSWORD_RESET_REQUEST,
    constants.PASSWORD_RESET_FAILURE,
    constants.PASSWORD_RESET_SUCCESS,
);
