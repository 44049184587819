import React from 'react';
import {
    Row, Col, Button, Layout, Avatar, Dropdown, Image, Menu as AntMenu,
} from 'antd';
import { isEmpty } from 'underscore';
import { Link } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import userAvatar from '../../../assets/images/oval.png';
import downArrow from '../../../assets/images/arrow-down.png';
import Menu from '../Menu';
import './header.scss';

export default function () {
    const {
        toolbar, menu, backLink, title, menuPlacement, menuWidth, floating,
        beforeHeader, afterHeader, isLoggedIn, loginUser, menuText,
    } = this.props;

    const subMenu = (
        <AntMenu>
            <AntMenu.Item icon={<LogoutOutlined />}>
                <Link to="/logout">Logout</Link>
            </AntMenu.Item>
        </AntMenu>
    );

    return (
        <div className={`header ${floating ? 'floating-header' : ''}`}>
            {beforeHeader}
            <Layout.Header className="header__inner">
                <Row type="flex" justify="space-between" align="middle">
                    <Col md={8} xs={0}>
                        {isLoggedIn
                            ? (
                                <>
                                    {backLink}
                                    {menu.length > 0 ? <><Menu items={menu} placement={menuPlacement} width={menuWidth} /> {menuText}</> : null}
                                </>
                            )
                            : null}
                    </Col>
                    <Col md={0} xs={4}>
                        {isLoggedIn
                            ? (
                                <>
                                    {backLink}
                                    {menu.length > 0 ? <><Menu items={menu} placement={menuPlacement} width={menuWidth} /></> : null}
                                </>
                            )
                            : null}
                    </Col>
                    <Col md={8} xs={10}>
                        <h3 className="header__title h-4">{title}</h3>
                    </Col>
                    <Col md={8} xs={0}>
                        {isLoggedIn && !isEmpty(loginUser)
                            ? (
                                <div className="d-flex justify-content-end">
                                    <Dropdown overlay={subMenu} trigger={['click']} overlayClassName="right-menu-overlay">
                                        <div>
                                            <Avatar src={userAvatar} />
                                            <img alt="User" src={downArrow} style={{ marginLeft: '8px' }} />
                                        </div>
                                    </Dropdown>
                                </div>
                            )
                            : null}
                    </Col>
                    <Col md={0} xs={4}>
                        {isLoggedIn && !isEmpty(loginUser)
                            ? (
                                <div className="d-flex justify-content-end">
                                    <Dropdown overlay={subMenu}>
                                        <>
                                            <Avatar src={userAvatar} />
                                        </>
                                    </Dropdown>
                                </div>
                            )
                            : null}
                    </Col>
                </Row>
            </Layout.Header>
            {afterHeader}
        </div>
    );
}
//        {toolbar.map((button, buttonIndex) => (
//     <Button
//         key={button.id || buttonIndex}
//         size={button.size || 'default'}
//         className="toolbar-button"
//         href={button.link || '#'}
//         onClick={button.onClick || null}>
//
//         <span>{button.label}</span>
//     </Button>
// ))}
