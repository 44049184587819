import React, { useState, useEffect, useRef } from 'react';
import { Input } from '.';

let autoComplete;

const loadScript = (url, callback) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef, onUpdate) {
    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        { types: ['(cities)'], componentRestrictions: { country: 'uk' } },
    );
    autoComplete.setFields(['address_components', 'formatted_address']);
    autoComplete.addListener('place_changed', () => handlePlaceSelect(updateQuery, onUpdate));
}

async function handlePlaceSelect(updateQuery, onUpdate) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    onUpdate(query);
    // console.log(addressObject);
}

function SearchLocationInput({ apiKey, onUpdate, val }) {
    const [query, setQuery] = useState(val);
    const autoCompleteRef = useRef(null);

    useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef, onUpdate),
        );
    }, []);

    return (
        <input
            className="ant-input"
            ref={autoCompleteRef}
            onChange={event => {
                onUpdate(event.target.value);
                setQuery(event.target.value);

            }}
            placeholder="Enter a City"
            value={query} />
    );
}

export default SearchLocationInput;
