import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import DefaultModelImage from '../../../assets/images/default-avatar.png';

const MessageListItem = ({ image, full_name, job_name, first_day, time, slug, selected = false, onClick = null }) => (
    <>
        <div
            className={`messenger${selected ? ' selected' : ''}`}
            onClick={(e) => {
                e.stopPropagation();
                onClick();
            }}>
            <div className="messenger-image">
                <img src={image || DefaultModelImage} alt="" />
            </div>
            <div className="messenger-details">
                <div className="messenger-title">
                    {full_name || 'Model Name'}
                    <span className="messenger-time">{time || '10:55'}</span>
                </div>
                <div className="messenger-sub-heading">
                    <span className="messenger-sub-title">Job:</span>
                    <span className="messenger-sub-value">
                        <Link to={`/job/${slug}`}>{job_name || 'Here'}</Link>
                    </span>
                </div>
                <div className="messenger-sub-heading">
                    <span className="messenger-sub-title">First day:</span>
                    <span className="messenger-sub-value">
                        {first_day || '22/10/2020'}
                    </span>
                </div>
            </div>
        </div>
    </>
);
export default MessageListItem;
