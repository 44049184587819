import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';
import SelectModelIcon from '../../assets/images/select-model.png';
import SelectedModelIcon from '../../assets/images/selected-model.png';
import DeleteModelIcon from '../../assets/images/delete-model.png';
import './modelBox.scss';

const ModelBox = ({ src, name, selected, type, onClick, disbaleLazyLoad = false, disableActionIcon = false }) => {
    const className = `find-image ${type == 'small' ? ' find-image-small' : ''}`;
    const width = type == 'small' ? ' 184px' : '226px';
    const height = type == 'small' ? ' 184px' : '226px';
    let actionIcon = SelectModelIcon;
    if (selected == true) {
        actionIcon = SelectedModelIcon;
    }
    if (type == 'small') {
        actionIcon = DeleteModelIcon;
    }

    if (type == 'small') {
        return (
            <div className={className}>
                {!disableActionIcon
                    ? (
                        <div className="action-button">
                            <img alt="Action Button" src={actionIcon} onClick={onClick || null} />
                        </div>
                    )
                    : null}

                <img alt={name} src={src} width={width} height={height} />
                {/*<div className="find-name">{name}</div>*/}
            </div>
        );
    }

    if (disbaleLazyLoad) {
        return (
            <div className={className}>
                {!disableActionIcon
                    ? (
                        <div className="action-button">
                            <img alt="Action Button" src={actionIcon} onClick={onClick || null} />
                        </div>
                    )
                    : null}
                <img alt={name} src={src} width={width} height={height} />
                {/*<div className="find-name">{name}</div>*/}
            </div>
        );
    }

    return (
        <LazyLoad className={className}>
            <div className="action-button">
                <img alt="Action Button" src={actionIcon} onClick={onClick || null} />
            </div>
            <img alt={name} src={src} width={width} height={height} />
            {/*<div className="find-name">{name}</div>*/}
        </LazyLoad>
    );
};

export default ModelBox;
