/* global window:true */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
    Button,
    Form,
    Input,
    InputNumber,
    Radio,
    Row,
    Col,
    Select,
    Spin,
} from 'antd';
import { debounce, isArray, isEmpty, isUndefined } from 'underscore';
import { getJobDaysRequest, daysPriceCalculation } from '../../../core/job/jobActions';
import locationImage from '../../../assets/images/location.png';
import './steps.scss';
import { Checkbox, DatePicker } from '../../../components/elements/FormElements';

const {
    JOB_USAGE,
} = require('../../../core/constants').default;

let typingTimer = null;
const StepRatesAndDays = ({ form, formErrors, setFormErrors }) => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    const isFetching = useSelector(state => state.job.isFetching);
    const daysIsFetching = useSelector(state => state.job.daysIsFetching);
    const jobDays = useSelector(state => state.job.jobDays);
    const jobDaysDetails = useSelector(state => state.job.jobDaysDetails);
    const jobFee = useSelector(state => state.job.jobFee);

    useEffect(() => {
        dispatch(getJobDaysRequest({ slug }));
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => form.resetFields(), [jobDays]);
    const onFilterFieldsChange = (changedFields, allFields) => {
        new Promise((resolve, reject) => {
            if (typingTimer != null) {
                clearTimeout(typingTimer);
            }
            let errors = '';
            const filterErrors = allFields.filter(field => field.errors.length > 0);
            filterErrors.forEach(formField => {
                if (formField.errors.length > 0 && errors == '') {
                    errors = formField.errors[0];
                    resolve(errors);
                }
            });
            if (filterErrors.length === 0) {
                resolve(errors);
            }

        }).then(errors => {
            setFormErrors(errors);
            return errors;
        }).then(errors => {
            if (errors == '') {
                const values = [];
                allFields.filter((field) => {
                    if (isArray(field.value)) {
                        return (field.value).length > 0;
                    }
                    return !isUndefined(field.value) && field.value !== false;
                }).forEach((field) => {
                    if (field.name[0] == 'job_first_day') {
                        field.value = (field.value).format('YYYY-MM-DD');
                    }
                    values[field.name[0]] = field.value;
                });
                typingTimer = setTimeout(() => dispatch(getJobDaysRequest({ slug, params: values })), 3000);
            }
        }).catch(e => console.log('>>>>>>>', e));
    };
    const firstDay = (jobDays && jobDays[0] && jobDays[0].date ? moment(jobDays[0].date, 'YYYY-MM-DD') : moment());
    const workingDetails = jobDaysDetails && jobDaysDetails.working_day_and_hours;
    const usageRights = (jobDaysDetails && jobDaysDetails.usage_rights) || '1';
    let no_of_models = jobDaysDetails && jobDaysDetails.no_of_models;
    let no_of_models_other = 0;
    if (no_of_models > 3) {
        no_of_models_other = no_of_models;
        no_of_models = 'other';
    }
    let noOfDays = 0;
    let noOfDaysOther = 0;
    if (isArray(jobDays)) {
        jobDays.forEach((jobDay) => {
            noOfDays += parseFloat(jobDay.days_count);
        });
        if (noOfDays > 2.5) {
            noOfDaysOther = noOfDays;
            noOfDays = 'other';
        }
    }
    const selectedJobDays = noOfDays == 'other' ? noOfDaysOther : noOfDays;
    const selectedJobModels = no_of_models == 'other' ? no_of_models_other : no_of_models;
    const [daysOtherBox, setDaysOtherBox] = useState(noOfDays == 'other');
    const [noOfModels, setNoOfModels] = useState((no_of_models == 'other' ? no_of_models_other : (no_of_models || '')).toString());
    const [modelsOtherBox, setModelsOtherBox] = useState(no_of_models == 'other');
    const [selectedPrice, setSelectedPrice] = useState(jobFee);
    const [selectedDays, setSelectedDays] = useState(selectedJobDays);
    const [jobUsage, setJobUsage] = useState(usageRights);
    const daysHelp = <span className="custom-help">Half a day is 4 hours or less, full day is up to 8 hours</span>;
    const modelsHelp = <span className="custom-help">How many models do you need in total for this job?</span>;
    const feeHelp = <span className="custom-help">The total fee you will pay the model for their time, usage, and travel. More presigious models will expect a higher job fee.</span>;
    let usageHelp = (
        <span className="custom-help">{JOB_USAGE[0]} <a href="https://www.modlapp.com/usage" target="_blank">more details</a>
        </span>
    );
    if (jobUsage == '2') {
        usageHelp = (
            <span className="custom-help">{JOB_USAGE[1]} <a href="https://www.modlapp.com/usage" target="_blank">more details</a>
            </span>
        );
    } else if (jobUsage == '3') {
        usageHelp = (
            <span className="custom-help">{JOB_USAGE[2]} <a href="https://www.modlapp.com/usage" target="_blank">more details</a>
            </span>
        );
    } else if (jobUsage == '4') {
        usageHelp = (
            <span className="custom-help">{JOB_USAGE[3]} <a href="https://www.modlapp.com/usage" target="_blank">more details</a>
            </span>
        );
    } else if (jobUsage == '6') {
        usageHelp = (
            <span className="custom-help">{JOB_USAGE[5]} <a href="https://www.modlapp.com/usage" target="_blank">more details</a>
            </span>
        );
    } else if (jobUsage == '5') {
        usageHelp = (
            <span className="custom-help">{JOB_USAGE[4]} <a href="https://www.modlapp.com/usage" target="_blank">more details</a>
            </span>
        );
    }

    const priceSummary = [];
    const calcualtedJobFee = selectedPrice > 0 ? selectedPrice : jobFee;
    const calculatedSelectedDays = selectedDays > 0 ? selectedDays : selectedJobDays;
    for (let i = 1; i <= selectedJobModels; i += 1) {
        priceSummary.push(<Row key={i} className="price-row">
            <Col xs={20}>Model {i}: Basic Job Fee</Col>
            <Col xs={4}>£{(calcualtedJobFee * calculatedSelectedDays).toFixed(2)}</Col>
        </Row>);
    }

    return (
        <div className="main-content with-margin-top with-margin-bottom">
            <Spin spinning={daysIsFetching}>
                <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="vertical"
                    form={form}
                    onFieldsChange={onFilterFieldsChange} // {debounce(onFilterFieldsChange, 5000)}
                    initialValues={{
                        layout: 'vertical',
                        no_of_days: noOfDays.toString(),
                        no_of_days_other: noOfDaysOther,
                        job_first_day: firstDay,
                        working_details: workingDetails,
                        no_of_models: (no_of_models || '').toString(),
                        no_of_models_other: (no_of_models_other || '').toString(),
                        usage_rights: jobUsage,
                        job_fee: jobFee,
                    }}>
                    <Form.Item label="Number of job days (per chosen model)"
                        name="no_of_days"
                        help={daysHelp}
                        rules={[
                            {
                                required: true,
                                message: 'Please select a day!',
                            },
                        ]}>
                        <Radio.Group buttonStyle="solid"
                            onChange={(e) => {
                                let price = 0;
                                if (e.target.value == 'other') {
                                    setDaysOtherBox(true);
                                    // setSelectedPrice(300 * selectedDays);
                                } else {
                                    setDaysOtherBox(false);
                                    price = (selectedPrice / selectedDays) * parseFloat(e.target.value);
                                    setSelectedPrice(price);
                                    form.setFieldsValue({ job_fee: price });
                                    setSelectedDays(e.target.value);

                                }
                                dispatch(daysPriceCalculation(true));
                            }}>
                            <Radio.Button value="0.5">0.5</Radio.Button>
                            <Radio.Button value="1">1</Radio.Button>
                            <Radio.Button value="1.5">1.5</Radio.Button>
                            <Radio.Button value="2">2</Radio.Button>
                            <Radio.Button value="2.5">2.5</Radio.Button>
                            <Radio.Button value="other">Other</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Row className={!daysOtherBox ? 'hide-component' : ''} style={{ marginTop: '-25px' }}>
                        <Col xs={24}>
                            <Form.Item name="no_of_days_other">
                                <InputNumber placeholder="Custom days" min={3} max={10} step={0.5} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Number of model required "
                        name="no_of_models"
                        help={modelsHelp}
                        rules={[
                            {
                                required: true,
                                message: 'Please select no of models!',
                            },
                        ]}>
                        <Radio.Group buttonStyle="solid"
                            onChange={(e) => {
                                if (e.target.value == 'other') {
                                    setModelsOtherBox(true);
                                } else {
                                    setNoOfModels(e.target.value);
                                    setModelsOtherBox(false);
                                }
                                dispatch(daysPriceCalculation(true));
                            }}>
                            <Radio.Button value="1">1</Radio.Button>
                            <Radio.Button value="2">2</Radio.Button>
                            <Radio.Button value="3">3</Radio.Button>
                            <Radio.Button value="other">Other</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Row className={!modelsOtherBox ? 'hide-component' : ''} style={{ marginTop: '-25px' }}>
                        <Col xs={24}>
                            <Form.Item name="no_of_models_other" valuePropName="checked">
                                <InputNumber
                                    placeholder="Models other"
                                    min={4}
                                    max={10}
                                    step={1}
                                    onChange={(val) => {
                                        setNoOfModels(val);
                                        dispatch(daysPriceCalculation(true));
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="First day of job"
                        name="job_first_day"
                        rules={[
                            {
                                required: true,
                                message: 'Specify a job start date',
                            },
                        ]}>
                        <DatePicker
                            format="DD/MM/YYYY"
                            placeholder="29/02/2020"
                            disabledDate={(current) => current && current < moment()}
                            // disabledDate={(current) => current.format('DD/MM/YYYY') < moment().format('DD/MM/YYYY')}
                            onChange={(date, dateString) => console.log()} />
                    </Form.Item>
                    <Form.Item name="working_details" label="Working days and hours (details)">
                        <Input.TextArea rows={4} placeholder="E.g.  Monday 12th November from 1pm and Tuesday 5th from 9am" />
                    </Form.Item>
                    <Form.Item label="Model pay / Basic job fee" name="job_fee" help={feeHelp}>
                        <Radio.Group buttonStyle="solid"
                            onChange={(e) => {
                                if (e.target.value == 'other') {
                                    setDaysOtherBox(true);
                                } else {
                                    setSelectedPrice(e.target.value);
                                    setDaysOtherBox(false);
                                }
                                dispatch(daysPriceCalculation(true));
                            }}>
                            <Radio.Button value={300 * selectedJobDays}>£{300 * selectedJobDays}</Radio.Button>
                            <Radio.Button value={350 * selectedJobDays}>£{350 * selectedJobDays}</Radio.Button>
                            <Radio.Button value={400 * selectedJobDays}>£{400 * selectedJobDays}</Radio.Button>
                            <Radio.Button value={500 * selectedJobDays}>£{500 * selectedJobDays}</Radio.Button>
                            <Radio.Button value={600 * selectedJobDays}>£{600 * selectedJobDays}</Radio.Button>
                            <Radio.Button value={700 * selectedJobDays}>£{700 * selectedJobDays}</Radio.Button>
                            <Radio.Button value="other">Other</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <div className="price-summary">
                        <Row>
                            <Col xs={24} className="form-header">
                                Total you will pay
                            </Col>
                        </Row>
                        {/* const priceSummary = [];
                        for (let i = 1; i <= selectedJobModels; i += 1) {
                            priceSummary.push(<Row key={i} className="price-row">
                        <Col xs={20}>Model {i}: Basic Job Fee</Col>
                        <Col xs={4}>£{(selectedPrice * selectedDays).toFixed(2)}</Col>
                        </Row>);
                        } */}
                        {!isFetching
                            ? (
                                <>
                                    {priceSummary}
                                    <Row className="price-row">
                                        <Col xs={20}>Booking fee (19%)</Col>
                                        <Col xs={4}>£{(calcualtedJobFee * 19 / 100).toFixed(2)}</Col>
                                    </Row>
                                    <Row className="form-total-row">
                                        <Col xs={20}>Total</Col>
                                        <Col xs={4}>£{(((jobFee * calculatedSelectedDays) * (noOfModels || selectedJobModels)) + (calcualtedJobFee * 19 / 100)).toFixed(2)}</Col>
                                    </Row>
                                </>
                            )
                            : null}
                    </div>
                    <Form.Item name="usage_rights" label="Required usage rights" className="form-sub-header" help={usageHelp}>
                        <Select
                            onChange={(value, option) => setJobUsage(value)}
                            placeholder="Select a option and change input text above">
                            <Select.Option value="1">Basic usage - online 1 year *</Select.Option>
                            <Select.Option value="2">Band 1 - UK 1 years, below line *</Select.Option>
                            <Select.Option value="3">Band 2 - EU 3 years, print + below line *</Select.Option>
                            <Select.Option value="4">Band 3 - World 5 years, print + digital + TV *</Select.Option>
                            <Select.Option value="5">N/A *</Select.Option>
                            <Select.Option value="6">Full buyout *</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
};

export default StepRatesAndDays;
