import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({

    GET_MESSAGE_LIST_REQUEST: null,
    GET_MESSAGE_LIST_SUCCESS: null,
    GET_MESSAGE_LIST_FAILURE: null,

    GET_SELECTED_CONVERSATION_REQUEST: null,
    GET_SELECTED_CONVERSATION_SUCCESS: null,
    GET_SELECTED_CONVERSATION_FAILURE: null,

    SEND_NEW_MESSAGE_REQUEST: null,
    SEND_NEW_MESSAGE_SUCCESS: null,
    SEND_NEW_MESSAGE_FAILURE: null,

});

export const {
    getMessageListRequest,
    getMessageListSuccess,
    getMessageListFailure,
} = createActions(
    constants.GET_MESSAGE_LIST_REQUEST,
    constants.GET_MESSAGE_LIST_SUCCESS,
    constants.GET_MESSAGE_LIST_FAILURE,
);

export const {
    getSelectedConversationRequest,
    getSelectedConversationSuccess,
    getSelectedConversationFailure,
} = createActions(
    constants.GET_SELECTED_CONVERSATION_REQUEST,
    constants.GET_SELECTED_CONVERSATION_SUCCESS,
    constants.GET_SELECTED_CONVERSATION_FAILURE,
);

export const {
    sendNewMessageRequest,
    sendNewMessageSuccess,
    sendNewMessageFailure,
} = createActions(
    constants.SEND_NEW_MESSAGE_REQUEST,
    constants.SEND_NEW_MESSAGE_SUCCESS,
    constants.SEND_NEW_MESSAGE_FAILURE,
);
