import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import Shave from '../../elements/ReactShave';
import DefaultModelImage from '../../../assets/images/default-avatar.png';

const MessageHeader = ({ image, full_name, job_name, first_day, slug }) => (
    <div className="conversation-header">
        <div className="conversation-messenger-image">
            <img src={image || DefaultModelImage} alt="" />
        </div>
        <div className="conversation-messenger-model-name">
            {full_name || 'Model Name'}
        </div>
        <div className="conversation-messenger-details">
            <div>
                <span className="conversation-messenger-sub-title">Job:</span>
                <span className="conversation-messenger-sub-value">
                    <Link to={`job/${slug}`}>
                        <Shave maxHeight={30}>{job_name || 'Here'}</Shave>
                    </Link>
                </span>
            </div>
            <div>
                <span className="conversation-messenger-sub-title">First day:</span>
                <span className="conversation-messenger-sub-value">
                    {first_day || '22/10/2020'}
                </span>
            </div>
        </div>
    </div>
);

export default MessageHeader;
