import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { mapObject } from 'underscore';

const { ENV, VERSION, BUGSNAG_KEY, DEBUG } = require('../constants').default;

const debug = {};

const getWrapper = () => {
    if (DEBUG || !BUGSNAG_KEY) {
        return ({ children }) => children;
    }

    Bugsnag.start({
        apiKey: BUGSNAG_KEY,
        plugins: [new BugsnagPluginReact(React)],
        releaseStage: ENV,
        appVersion: VERSION,
    });

    return Bugsnag.getPlugin('react');
};

const notify = (message, data) => {
    if (DEBUG || !BUGSNAG_KEY) {
        return;
    }

    const onError = e => mapObject(data, (value, key) => {
        e.addMetadata(key, value || {});
    });

    Bugsnag.addOnError(onError);
    Bugsnag.notify(new Error(message));
    Bugsnag.removeOnError(onError);
};

debug.getWrapper = getWrapper;
debug.notify = notify;

export default debug;
