/* global window:true */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Pagination, Row, Spin } from 'antd';
import { PlusOutlined, LeftOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import MODLLogo from '../../assets/images/modl.svg';
import AfterHeader from '../../components/layout/AfterHeader';
import JobBox from '../Dashboard/JobBox';
import { Button } from '../../components/elements/FormElements';
import './job.scss';
import ChevronRight from '../../assets/images/arrow-orange.png';
import ModelBoxWithInfo from '../../components/ModelBoxWithInfo';
import history from '../../core/utils/history';
import { getCompleteJobRequest } from '../../core/job/jobActions';

// Statuses pages
import { PendingBackLink, PendingStatus, PendingHeader } from './Statuses/pending';
import { RejectedBackLink, RejectedStatus, RejectedHeader } from './Statuses/rejected';
import { ActiveBackLink, ActiveStatus, ActiveHeader } from './Statuses/active';
import { StartedBackLink, StartedStatus, StartedHeader } from './Statuses/started';
import { CompletedBackLink, CompletedStatus, CompletedHeader } from './Statuses/completed';

const Job = () => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    const isFetching = useSelector(state => state.job.isFetching);
    const completeJob = useSelector(state => state.job.completeJob);
    useEffect(() => {
        dispatch(getCompleteJobRequest({ slug }));
        window.scrollTo(0, 0);
    }, []);

    let backLink = (
        <Row align="middle">
            <Col xs={24} md={2}>
                <LeftOutlined style={{ fontSize: '24px' }} onClick={() => history.push('/dashboard')} />
            </Col>
        </Row>
    );
    let subHeader = null;
    let content = null;

    if (completeJob?.status == 'pending') {
        backLink = <PendingBackLink title={completeJob?.title} />;
        subHeader = <PendingHeader />;
        content = <PendingStatus completeJob={completeJob} />;
    } else if (completeJob?.status == 'rejected') {
        backLink = <RejectedBackLink title={completeJob?.title} />;
        subHeader = <RejectedHeader />;
        content = <RejectedStatus completeJob={completeJob} />;
    } else if (completeJob?.status == 'active') {
        backLink = <ActiveBackLink title={completeJob?.title} />;
        subHeader = <ActiveHeader completeJob={completeJob} />;
        content = <ActiveStatus completeJob={completeJob} />;
    } else if (completeJob?.status == 'started') {
        backLink = <StartedBackLink title={completeJob?.title} />;
        subHeader = <StartedHeader completeJob={completeJob} />;
        content = <StartedStatus completeJob={completeJob} />;
    } else if (completeJob?.status == 'completed') {
        backLink = <CompletedBackLink title={completeJob?.title} />;
        subHeader = <CompletedHeader />;
        content = <CompletedStatus completeJob={completeJob} />;
    }

    return (
        <Layout
            className="job"
            title={<div>Welcome</div>}
            headerTitle={<img alt="MODL" src={MODLLogo} />}
            afterHeader={<AfterHeader content={subHeader} />}
            menu={[]}
            menuText="Your Jobs"
            menuWidth={350}
            menuPlacement="left"
            // toolbar={[]}
            floatingHeader={false}
            // footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2019</div>}
            // floatingFooter
            showFooter={false}
            // breadcrumb={navigation}
            backLink={backLink}>
            <Spin size="large" spinning={false}>
                {content}
            </Spin>
        </Layout>
    );
};

export default Job;
