import React from 'react';
import { Link } from 'react-router-dom';
import { Drawer, Avatar } from 'antd';
import Icon, { MenuOutlined } from '@ant-design/icons';
import { map, isNull } from 'underscore';
import { Button } from '../../elements/FormElements';
import { getBaseUrl } from '../../../core/utils/api';
import userAvatar from '../../../assets/images/default-avatar.png';
import './menu.scss';

export default function () {
    const {
        loginUser, items, logo, placement, width,
    } = this.props;
    const { menuOpen } = this.state;
    return (
        <div className="menu">
            <Button type="primary" className="menu-button" onClick={this.openDrawer} label={<MenuOutlined />} />
            <Drawer
                visible={menuOpen}
                onClose={this.closeDrawer}
                placement={placement}
                width={width}>
                <div className="menu__header">
                    {!isNull(logo)
                        ? (
                            <Link to="/">
                                <img className="github" width={256} src={logo} alt="" />
                            </Link>
                        )
                        : null}
                </div>
                <div className="menu__content">
                    <ul className="menu__nav">
                        <li>
                            <Link to="/dashboard" className="menu__navlink">
                                Dashboard
                            </Link>
                        </li>
                        <li>
                            <Link to="/messages" className="menu__navlink">
                                Messages
                            </Link>
                        </li>
                        <li>
                            <Link to="/my-account" className="menu__navlink">
                                My Account
                            </Link>
                        </li>
                    </ul>
                    {/* <ul className="menu__nav">
                        {map(items, (item, itemIndex) => (
                            <li key={item.key || `menu-item-${itemIndex}`}>
                                <Link to={item.link} className={`menu__navlink ${item.className || ''}`} style={item.style || null}>

                                    <span>{item.label}</span>
                                </Link>
                            </li>
                        ))}
                    </ul> */}
                </div>
                <div className="menu__footer">
                    <div className="profile">
                        <Avatar src={userAvatar} size={46} alt="" />
                        <div className="profile__info">
                            <Link to="/logout">Logout</Link>
                            {/* <a href={`mailto:${loginUser}`}>{loginUser}</a> */}
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}
