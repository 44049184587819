import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({

    GET_JOB_LIST_REQUEST: null,
    GET_JOB_LIST_SUCCESS: null,
    GET_JOB_LIST_FAILURE: null,

    GET_JOB_REQUIREMENT_REQUEST: null,
    GET_JOB_REQUIREMENT_SUCCESS: null,
    GET_JOB_REQUIREMENT_FAILURE: null,

    UPDATE_JOB_BASKET_REQUEST: null,
    UPDATE_JOB_BASKET_SUCCESS: null,
    UPDATE_JOB_BASKET_FAILURE: null,

    GET_JOB_DETAILS_REQUEST: null,
    GET_JOB_DETAILS_SUCCESS: null,
    GET_JOB_DETAILS_FAILURE: null,

    GET_JOB_DAYS_REQUEST: null,
    GET_JOB_DAYS_SUCCESS: null,
    GET_JOB_DAYS_FAILURE: null,

    CLEAR_SELECTION_REQUEST: null,
    CLEAR_SELECTION_SUCCESS: null,
    CLEAR_SELECTION_FAILURE: null,

    CREATE_JOB_REQUEST: null,
    CREATE_JOB_SUCCESS: null,
    CREATE_JOB_FAILURE: null,

    SUBMIT_JOB_REQUEST: null,
    SUBMIT_JOB_SUCCESS: null,
    SUBMIT_JOB_FAILURE: null,

    GET_COMPLETE_JOB_REQUEST: null,
    GET_COMPLETE_JOB_SUCCESS: null,
    GET_COMPLETE_JOB_FAILURE: null,

    CONFIRM_JOB_MODEL_REQUEST: null,
    CONFIRM_JOB_MODEL_SUCCESS: null,
    CONFIRM_JOB_MODEL_FAILURE: null,

    DAYS_PRICE_CALCULATION: null,

});

export const {
    getJobListRequest,
    getJobListSuccess,
    getJobListFailure,
} = createActions(
    constants.GET_JOB_LIST_REQUEST,
    constants.GET_JOB_LIST_SUCCESS,
    constants.GET_JOB_LIST_FAILURE,
);

export const {
    getJobRequirementRequest,
    getJobRequirementSuccess,
    getJobRequirementFailure,
} = createActions(
    constants.GET_JOB_REQUIREMENT_REQUEST,
    constants.GET_JOB_REQUIREMENT_SUCCESS,
    constants.GET_JOB_REQUIREMENT_FAILURE,
);

export const {
    updateJobBasketRequest,
    updateJobBasketSuccess,
    updateJobBasketFailure,
} = createActions(
    constants.UPDATE_JOB_BASKET_REQUEST,
    constants.UPDATE_JOB_BASKET_SUCCESS,
    constants.UPDATE_JOB_BASKET_FAILURE,
);

export const {
    getJobDetailsRequest,
    getJobDetailsSuccess,
    getJobDetailsFailure,
} = createActions(
    constants.GET_JOB_DETAILS_REQUEST,
    constants.GET_JOB_DETAILS_SUCCESS,
    constants.GET_JOB_DETAILS_FAILURE,
);

export const {
    getJobDaysRequest,
    getJobDaysSuccess,
    getJobDaysFailure,
} = createActions(
    constants.GET_JOB_DAYS_REQUEST,
    constants.GET_JOB_DAYS_SUCCESS,
    constants.GET_JOB_DAYS_FAILURE,
);

export const {
    clearSelectionRequest,
    clearSelectionSuccess,
    clearSelectionFailure,
} = createActions(
    constants.CLEAR_SELECTION_REQUEST,
    constants.CLEAR_SELECTION_SUCCESS,
    constants.CLEAR_SELECTION_FAILURE,
);

export const {
    createJobRequest,
    createJobSuccess,
    createJobFailure,
} = createActions(
    constants.CREATE_JOB_REQUEST,
    constants.CREATE_JOB_SUCCESS,
    constants.CREATE_JOB_FAILURE,
);

export const {
    submitJobRequest,
    submitJobSuccess,
    submitJobFailure,
} = createActions(
    constants.SUBMIT_JOB_REQUEST,
    constants.SUBMIT_JOB_SUCCESS,
    constants.SUBMIT_JOB_FAILURE,
);

export const {
    getCompleteJobRequest,
    getCompleteJobSuccess,
    getCompleteJobFailure,
} = createActions(
    constants.GET_COMPLETE_JOB_REQUEST,
    constants.GET_COMPLETE_JOB_SUCCESS,
    constants.GET_COMPLETE_JOB_FAILURE,
);

export const {
    confirmJobModelRequest,
    confirmJobModelSuccess,
    confirmJobModelFailure,
} = createActions(
    constants.CONFIRM_JOB_MODEL_REQUEST,
    constants.CONFIRM_JOB_MODEL_SUCCESS,
    constants.CONFIRM_JOB_MODEL_FAILURE,
);

export const {
    daysPriceCalculation,
} = createActions(
    constants.DAYS_PRICE_CALCULATION,
);
