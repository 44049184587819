/* global localStorage: true */
import { call, put, takeEvery, all } from 'redux-saga/effects';
import { notification } from 'antd';
import { fetchApiAuth } from '../utils/api';
import history from '../utils/history';

import {
    getMessageListSuccess,
    getMessageListFailure,

    getSelectedConversationSuccess,
    getSelectedConversationFailure,

    sendNewMessageSuccess,
    sendNewMessageFailure,

} from './messageActions';

const {
    GET_MESSAGE_LIST_REQUEST,
    GET_SELECTED_CONVERSATION_REQUEST,
    SEND_NEW_MESSAGE_REQUEST,
} = require('./messageActions').constants;

function* getMessageList() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/message',
        });
        yield put(getMessageListSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Unable to login' });
        yield put(getMessageListFailure(e));
    }
}

function* getConversation(action) {
    const params = action?.payload || {};
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/message/conversation',
            body: params,
        });
        yield put(getSelectedConversationSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Unable to login' });
        yield put(getSelectedConversationFailure(e));
    }
}

function* sendMessage(action) {
    const params = action?.payload || {};
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/message/send',
            body: params,
        });
        yield put(sendNewMessageSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Unable to login' });
        yield put(sendNewMessageFailure(e));
    }
}

/**
 * Watch actions
 */
export default function* messageSaga() {
    yield all([
        takeEvery(GET_MESSAGE_LIST_REQUEST, getMessageList),
        takeEvery(GET_SELECTED_CONVERSATION_REQUEST, getConversation),
        takeEvery(SEND_NEW_MESSAGE_REQUEST, sendMessage),
    ]);
}
