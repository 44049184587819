import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import StepFind from './steps/StepFind';
import StepJobDetails from './steps/StepJobDetails';
import StepRatesAndDays from './steps/StepRatesAndDays';
import StepReview from './steps/StepReview';
import Layout from '../../components/layout/Layout';
import MODLLogo from '../../assets/images/modl.svg';
import StepsHeader from './steps/StepsHeader';
import StepsFooter from './steps/StepsFooter';
import './search.scss';
import { LeftOutlined } from '@ant-design/icons';
import history from '../../core/utils/history';

const Search = () => {
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];
    const params = useParams();
    const [form] = Form.useForm();
    const isFetching = useSelector(state => state.job.isFetching);
    const matchingModels = useSelector(state => state.job.matchingModels);
    const [formErrors, setFormErrors] = useState('');
    const [paypalSDKReady, setPaypalSDKReady] = useState(false);
    let step = '';
    if (params && params.step) {
        step = params.step;
    }
    let stepScreen = '';
    if (step == 'first') {
        stepScreen = <StepFind params={params} />;
    } else if (step == 'second') {
        stepScreen = <StepJobDetails params={params} form={form} formErrors={formErrors} setFormErrors={setFormErrors} />;
    } else if (step == 'third') {
        stepScreen = <StepRatesAndDays params={params} form={form} formErrors={formErrors} setFormErrors={setFormErrors} />;
    } else if (step == 'fourth') {
        stepScreen = <StepReview params={params} form={form} setPaypalSDKReady={setPaypalSDKReady} />;
    }
    const menuText = (
        <Row align="middle">
            <Col xs={0} md={24}>
                <div className="back-link-header">New Job</div>
            </Col>
        </Row>
    );

    return (
        <Layout
            className="search"
            title={<div>Welcome</div>}
            headerTitle={<img alt="MODL" src={MODLLogo} />}
            afterHeader={<StepsHeader params={params} />}
            menu={menu}
            menuWidth={350}
            menuPlacement="left"
            toolbar={[]}
            floatingHeader={false}
            footerContent={<StepsFooter params={params} formErrors={formErrors} paypalSDKReady={paypalSDKReady} />}
            floatingFooter
            // breadcrumb={navigation}
            backLink={null}>
            <Spin size="large" spinning={isFetching}>
                {stepScreen}
            </Spin>
        </Layout>
    );
};

export default Search;
