/* global window:true */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Pagination, Row, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { createJobRequest, getJobListRequest } from '../../core/job/jobActions';
import { Button } from '../../components/elements/FormElements';
import MODLLogo from '../../assets/images/modl.svg';
import AfterHeader from '../../components/layout/AfterHeader';
import JobBox from './JobBox';
import Layout from '../../components/layout/Layout';
import './dashboard.scss';

const Dashboard = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(state => state.job.isFetching);
    const jobs = useSelector(state => state.job.jobs);
    const jobsPagination = useSelector(state => state.job.jobsPagination);
    const jobsPageChange = (page, pageSize) => {
        dispatch(getJobListRequest({ page }));
    };
    const createNewJob = () => {
        dispatch(createJobRequest());
    };
    useEffect(() => {
        dispatch(getJobListRequest({ page: 1 }));
        window.scrollTo(0, 0);
    }, []);
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];
    const subHeader = (
        <Row>
            <Col md={19} xs={24} className="sub-header-wrapper">
                <span className="sub-header-title">Your MODL Jobs</span>
            </Col>
            <Col md={5} xs={24} className="sub-header-wrapper">
                <Button
                    onClick={createNewJob}
                    type="primary"
                    icon={<PlusOutlined style={{ fontSize: '14px' }} />}
                    label="New job" />
            </Col>
        </Row>
    );

    return (
        <Layout
            className="dashboard"
            title={<div>Welcome</div>}
            headerTitle={<img alt="MODL" src={MODLLogo} />}
            afterHeader={<AfterHeader content={subHeader} />}
            menu={menu}
            menuText="Your Jobs"
            menuWidth={350}
            menuPlacement="left"
            // toolbar={[]}
            floatingHeader={false}
            // footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2019</div>}
            // floatingFooter
            showFooter={false}
            // breadcrumb={navigation}
            backLink={null}>
            <Spin size="large" spinning={isFetching}>
                <Row className="main-content">
                    <Col xs={{ span: 22, offset: 1 }} md={24}>
                        {jobs.map((job) => (
                            <JobBox
                                key={`job-id-${job.id}`}
                                id={job.id}
                                title={job.title}
                                slug={job.slug}
                                status={job.status}
                                location={job.location_address}
                                activeStep={job.active_step}
                                firstDay={job.first_day}
                                description={job.description} />
                        ))}
                    </Col>
                    <Col xs={24} className="dashboard-pagination">
                        <Pagination
                            pageSize={jobsPagination.perPageCount}
                            total={jobsPagination.totalRecords}
                            onChange={jobsPageChange} />
                    </Col>
                </Row>
            </Spin>
        </Layout>
    );
};

export default Dashboard;
