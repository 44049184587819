import React from 'react';

const UsersSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g>
                        <path d="M0 0L24 0 24 24 0 24z" transform="translate(-342 -444) translate(312 436) translate(30 8)" />
                        <path fill="#FFF" fillRule="nonzero" d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V18c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05.02.01.03.03.04.04 1.14.83 1.93 1.94 1.93 3.41V18c0 .35-.07.69-.18 1H22c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5z" transform="translate(-342 -444) translate(312 436) translate(30 8)" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const MessageSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g>
                        <path d="M0 0L24 0 24 24 0 24z" transform="translate(-556 -444) translate(528 436) translate(28 8)" />
                        <path fill="#FBA310" fillRule="nonzero" d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-3 12H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1zm0-3H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1zm0-3H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1z" transform="translate(-556 -444) translate(528 436) translate(28 8)" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const AddModelSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 38 32">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g>
                        <g fill="#000">
                            <path d="M17.052 5.552c0 3.066-2.486 5.551-5.552 5.551-3.066 0-5.552-2.485-5.552-5.551C5.948 2.486 8.434 0 11.5 0c3.066 0 5.552 2.486 5.552 5.552M11.5 12.69C5.654 12.69.818 16.824 0 22.207h23c-.818-5.383-5.654-9.517-11.5-9.517" transform="translate(-760 -440) translate(760 440) translate(6) translate(4.5 5)" />
                        </g>
                        <path d="M0 0H32V32H0z" transform="translate(-760 -440) translate(760 440) translate(6)" />
                    </g>
                    <g fill="#000" transform="translate(-760 -440) translate(760 440) translate(0 9)">
                        <rect width="1.5" height="12" x="5.25" rx=".75" />
                        <path d="M6 0c.414 0 .75.336.75.75v10.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75V.75C5.25.336 5.586 0 6 0z" transform="rotate(90 6 6)" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const PencilSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g>
                        <path d="M0 0L24 0 24 24 0 24z" transform="translate(-360 -670) translate(312 662) translate(48 8)" />
                        <path fill="#FFF" fillRule="nonzero" d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" transform="translate(-360 -670) translate(312 662) translate(48 8)" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export {
    UsersSvg,
    MessageSvg,
    AddModelSvg,
    PencilSvg,
};
