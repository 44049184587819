import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';

import * as userActions from '../../core/user/userActions';

import DOM from './loginForm';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalForgetVisible: false,
        };
        this.view = DOM;
    }

    setModalForgetVisible(modalForgetVisible) {
        this.setState({ modalForgetVisible });
    }

    handleSubmit = (values) => {
        const { actions } = this.props;
        actions.userLoginRequest({
            username: values.email,
            password: values.password,
        });
    };

    handleForgotSubmit = (values) => {
        console.log('>>>>>>>>>>', values)
        const { actions } = this.props;
        actions.passwordResetRequest({
            email: values.email,
        });
        this.setModalForgetVisible(false);
    };

    render() {
        return this.view();
    }
}

LoginForm.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
