import React, { Component } from 'react';
import { Steps, Row, Col, Button as AntButton, Dropdown } from 'antd';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import StepImage from '../../../components/ModelBox';
import { Button } from '../../../components/elements/FormElements';
import ModelsBasket from '../../../assets/images/models-basket.png';
import ModelsBasketOpen from '../../../assets/images/models-basket-open.png';
import DeafultImage from '../../../assets/images/rectangle.png';
import * as jobActions from '../../../core/job/jobActions';

class StepsHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownOpen: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log(':::::::::', prevProps.matchingModels, this.props.matchingModels);
    }

    removeModelFromBasket = (model_id) => {
        const { actions, params } = this.props;
        const slug = params?.slug;
        actions.updateJobBasketRequest({
            slug,
            model_id,
        });
    }

    clearBasket = () => {
        const { actions, params } = this.props;
        const slug = params?.slug;
        actions.clearSelectionRequest({
            slug,
        });
    }

    render() {
        const { dropDownOpen } = this.state;
        const { params, basketModels } = this.props;
        const activeStep = params?.step;
        const buttonIcon = dropDownOpen ? ModelsBasketOpen : ModelsBasket;
        let firstStep = 'process';
        let secondStep = 'wait';
        let thirdStep = 'wait';
        let fourthStep = 'wait';
        switch (activeStep) {
        case 'second':
            firstStep = 'finish';
            secondStep = 'process';
            thirdStep = 'wait';
            fourthStep = 'wait';
            break;
        case 'third':
            firstStep = 'finish';
            secondStep = 'finish';
            thirdStep = 'process';
            fourthStep = 'wait';
            break;
        case 'fourth':
            firstStep = 'finish';
            secondStep = 'finish';
            thirdStep = 'finish';
            fourthStep = 'process';
            break;
        }

        const overlay = () => (
            <div className="model-basket">
                <div className="model-basket-header">
                    <div className="model-basket-header-title">Your selects/shortlist</div>
                </div>
                <div className="model-basket-content">
                    {/* {basketModels.map((model) => <StepImage type="small" key={model.key} src={model.src} name={model.name} selected={model.selected} />)} */}
                    {basketModels.map((model, modelIndex) => (
                        <StepImage
                            type="small"
                            key={modelIndex}
                            src={`https://testapi.modlapp.com/${model.approved_image || model.featured_image}`}
                            name={model.full_name}
                            selected={model.selected || false}
                            onClick={() => this.removeModelFromBasket(model.id)} />
                    ))}
                </div>
                <div className="model-basket-footer">
                    <Button
                        onClick={this.clearBasket}
                        label="Clear Selection" />
                </div>
            </div>
        );

        return (
            <div className="steps-container">
                <Row>
                    <Col xs={1} md={8} />
                    <Col xs={22} md={8}>
                        <Steps align="middle" className="search-steps">
                            <Steps.Step status={firstStep} title="FIND" />
                            <Steps.Step status={secondStep} title="JOB DETAILS" />
                            <Steps.Step status={thirdStep} title="RATES & DAYS" />
                            <Steps.Step status={fourthStep} title="REVIEW" />
                        </Steps>
                    </Col>
                    <Col xs={24} md={8}>
                        <Dropdown overlay={overlay} trigger={['click']} onVisibleChange={(visible) => this.setState({ dropDownOpen: visible })}>
                            <Button className="model-basket-btn" label={<><img src={buttonIcon} /><span>{basketModels.length}</span></>} />
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        );
    }
}
StepsHeader.defaultProps = {
    activeStep: 'first',
};

StepsHeader.propTypes = {
    activeStep: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.job.isFetching,
        matchingModels: state.job.matchingModels,
        basketModels: state.job.basketModels,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...jobActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StepsHeader);
