/* global window:true */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
    Row,
    Col,
} from 'antd';
import { debounce, isArray, isEmpty, isUndefined } from 'underscore';
import moment from 'moment';
import { UpdatePayment } from '../../../core/utils/helpers';
import { getJobDaysRequest, getJobDetailsRequest, submitJobRequest } from '../../../core/job/jobActions';
import { Button, PayPalButton, StripeButton } from '../../../components/elements/FormElements';
import StepImage from '../../../components/ModelBox';
import TestimonialBox from '../../../components/elements/TestimonialBox';
import ChevronRight from '../../../assets/images/arrow-orange.png';
import PaypalIcon from '../../../assets/images/pp-icon.png';
import LocationIcon from '../../../assets/images/location.png';
import PhoneIcon from '../../../assets/images/phone.png';
import './steps.scss';

const {
    JOB_USAGE_SHORT,
    PAYPAL_CLIENT_ID,
} = require('../../../core/constants').default;

const typingTimer = null;
const StepReview = ({ setPaypalSDKReady }) => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    const isFetching = useSelector(state => state.job.isFetching);
    const jobDetails = useSelector(state => state.job.jobDetails);
    const jobFurtherDetails = useSelector(state => state.job.jobFurtherDetails);
    const matchingModels = useSelector(state => state.job.matchingModels);
    const jobDays = useSelector(state => state.job.jobDays);
    const jobDaysDetails = useSelector(state => state.job.jobDaysDetails);
    const jobFee = useSelector(state => state.job.jobFee);

    useEffect(() => {
        dispatch(getJobDetailsRequest({ slug }));
        dispatch(getJobDaysRequest({ slug }));
        window.scrollTo(0, 0);
    }, []);

    const savePayment = (data, details) => {
        const paymentDetails = UpdatePayment(data, details);
        dispatch(submitJobRequest({
            slug,
            ...paymentDetails,
        }));
    };

    /*
    const onToken = (token) => {
        fetch('/save-stripe-token', {
            method: 'POST',
            body: JSON.stringify(token),
        }).then(response => {
            response.json().then(data => {
                alert(`We are in business, ${data.email}`);
            });
        });
    }; */

    let no_of_models = jobDaysDetails && jobDaysDetails.no_of_models;
    let no_of_models_other = 0;
    if (no_of_models > 3) {
        no_of_models_other = no_of_models;
        no_of_models = 'other';
    }
    let noOfDays = 0;
    let noOfDaysOther = 0;
    if (isArray(jobDays)) {
        jobDays.forEach((jobDay) => {
            noOfDays += parseFloat(jobDay.days_count);
        });
        if (noOfDays > 2.5) {
            noOfDaysOther = noOfDays;
            noOfDays = 'other';
        }
    }
    const selectedJobDays = noOfDays == 'other' ? noOfDaysOther : noOfDays;
    const [noOfModels, setNoOfModels] = useState((no_of_models == 'other' ? no_of_models_other : (no_of_models || '')).toString());
    const [selectedPrice, setSelectedPrice] = useState(jobFee);
    const [selectedDays, setSelectedDays] = useState(selectedJobDays);
    const priceSummary = [];
    for (let i = 1; i <= noOfModels; i += 1) {
        priceSummary.push(<Row key={i} className="price-row">
            <Col xs={20}>Model {i}: Basic Job Fee</Col>
            <Col xs={4}>£{(selectedPrice * selectedDays).toFixed(2)}</Col>
        </Row>);
    }

    const basketModels = matchingModels.filter((model) => model.in_basket != '0');
    const usageRights = (jobDaysDetails && jobDaysDetails.usage_rights) || 1;
    const workingDetails = jobDaysDetails && jobDaysDetails.working_day_and_hours;

    const totalFee = (((jobFee * selectedDays) * noOfModels) + (selectedPrice * 19 / 100)).toFixed(2);

    return (
        <>
            <div className="main-content with-margin-top with-margin-bottom">
                <Row style={{ marginBottom: '32px' }}>
                    <Col span={24}>
                        <h1 className="heading-review-page">What happens after you pay?</h1>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '40px' }}>
                    <Col xs={6} className="review-boxes">
                        We send availability requests to your shortlisted models
                    </Col>
                    <Col xs={3} className="review-separator">
                        <img src={ChevronRight} alt="Review separator" />
                    </Col>
                    <Col xs={6} className="review-boxes">
                        You then see which models are available
                    </Col>
                    <Col xs={3} className="review-separator">
                        <img src={ChevronRight} alt="Review separator" />
                    </Col>
                    <Col xs={6} className="review-boxes">
                        Confirm which model(s) you’d like to work with
                    </Col>
                </Row>
                <Row style={{ marginBottom: '8px' }}>
                    <Col span={24}>
                        <span className="review-subtitle">MODL Booking Guarentee</span>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        MODL will refund any unused job fee if you don’t confirm a model that you have paid for.
                    </Col>
                </Row>
            </div>
            <div className="main-content with-margin-bottom">
                <Row style={{ marginBottom: '32px' }}>
                    <Col span={24}>
                        <h1 className="heading-review-page">Order total</h1>
                    </Col>
                </Row>
                {priceSummary}
                <Row className="price-row">
                    <Col xs={20}>Booking fee (19%)</Col>
                    <Col xs={4}>£{(selectedPrice * 19 / 100).toFixed(2)}</Col>
                </Row>
                <Row className="form-total-row">
                    <Col xs={20}>Total</Col>
                    <Col xs={4}>£{totalFee}</Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={8} offset={3}>
                        <PayPalButton
                            onButtonReady={() => setPaypalSDKReady(true)}
                            paymentSuccess={savePayment}
                            amount={totalFee} />
                        {/* <Button
                            disabled={false}
                            type="primary"
                            className="button-primary btn-paypal"
                            onClick={() => {}}
                            style={{ float: 'right', marginTop: 10 }}
                            label={<>Pay via <img src={PaypalIcon} alt="paypal" style={{ marginTop: '1px' }} /></>} /> */}
                    </Col>
                    <Col xs={2} style={{ textAlign: 'center', marginTop: '20px' }}>or</Col>
                    <Col xs={11}>
                        <StripeButton
                            paymentSuccess={(data, details) => {
                                const paymentDetails = UpdatePayment(data, details);
                                dispatch(submitJobRequest({
                                    slug,
                                    ...paymentDetails,
                                }));
                            }}
                            amount={totalFee} />
                    </Col>
                </Row>
            </div>
            <div className="main-content with-margin-bottom">
                <Row style={{ marginBottom: '32px' }}>
                    <Col xs={21}>
                        <h1 className="heading-review-page">Models in your shortlist</h1>
                    </Col>
                    <Col xs={3}>
                        <Link to={`/job/${slug}/first`} className="orange">Find more</Link>
                    </Col>
                </Row>
                {basketModels.map((model, modelIndex) => (
                    <StepImage
                        type="small"
                        key={modelIndex}
                        src={`https://testapi.modlapp.com/${model.approved_image || model.featured_image}`}
                        name={model.full_name}
                        selected={model.selected || false}
                        onClick={() => this.removeModelFromBasket(model.id)} />
                ))}
                <Row style={{ marginTop: '32px', marginBottom: '24px' }}>
                    <Col xs={21}>
                        <h1 className="heading-review-page">Job details</h1>
                    </Col>
                    <Col xs={3}>
                        <Link to={`/job/${slug}/first`} className="orange">Edit</Link>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                    <Col span={24}>
                        <span className="review-subtitle">Job title</span>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '32px' }}>
                    <Col span={24}>
                        {jobDetails && jobDetails.title}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                    <Col span={24}>
                        <span className="review-subtitle">Description</span>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '32px' }}>
                    <Col span={24}>
                        {jobDetails && jobDetails.description}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                    <Col span={24}>
                        <span className="review-subtitle">Contact</span>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                    <Col span={24}>
                        <img src={LocationIcon} alt="Location" /> {jobDetails && jobDetails.location_address}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '32px' }}>
                    <Col span={24}>
                        <img src={PhoneIcon} alt="Phone" /> {jobDetails && jobDetails.contact_telephone}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '32px' }}>
                    <Col xs={8}>
                        <Row>
                            <Col xs={24} style={{ marginBottom: '16px' }}>
                                <span className="review-subtitle">Number of job days</span>
                            </Col>
                            <Col xs={24}>{jobDays.length}</Col>
                        </Row>
                    </Col>
                    <Col xs={8}>
                        <Row>
                            <Col xs={24} style={{ marginBottom: '16px' }}>
                                <span className="review-subtitle">Job start day</span>
                            </Col>
                            <Col xs={24}>
                                {jobDays && jobDays[0] && jobDays[0].date ? moment(jobDays[0].date, 'YYYY-MM-DD').format('DD/MM/YYYY') : null}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={8}>
                        <Row>
                            <Col xs={24} style={{ marginBottom: '16px' }}>
                                <span className="review-subtitle">Usage</span>
                            </Col>
                            <Col xs={24}>
                                {JOB_USAGE_SHORT[usageRights - 1]}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                    <Col span={24}>
                        <span className="review-subtitle">Details about working days and hours</span>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '48px' }}>
                    <Col span={24}>
                        {workingDetails}
                    </Col>
                </Row>
                <TestimonialBox />
            </div>
        </>
    );
};

export default StepReview;
