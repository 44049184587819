// .env constants
const {
    REACT_APP_ENV,
    REACT_APP_VERSION,

    REACT_APP_APP_NAME,
    REACT_APP_URL,
    REACT_APP_API_URL,

    REACT_APP_BUGSNAG_KEY,
    REACT_APP_GOOGLE_MAPS_KEY,

    REACT_APP_PAYPAL_CLIENT_ID,
    REACT_APP_PAYPAL_SECRET,
    REACT_APP_STRIPE_KEY,
    REACT_APP_STRIPE_SECRET,
    JOB_USAGE,
} = process.env;

// APP CONSTATNS
const app = {
    ENV: REACT_APP_ENV,
    VERSION: REACT_APP_VERSION || '0.0.0',
    DEBUG: REACT_APP_ENV === 'local',
    MOCK: false,
    BACKEND: 'yii', // or 'laravel'

    APP_NAME: REACT_APP_APP_NAME || 'Scorchsoft React Boilerplate',

    APP_URL: REACT_APP_URL,
    API_URL: REACT_APP_API_URL,

    BUGSNAG_KEY: REACT_APP_BUGSNAG_KEY,
    GOOGLE_MAPS_KEY: REACT_APP_GOOGLE_MAPS_KEY,

    PAYPAL_CLIENT_ID: REACT_APP_PAYPAL_CLIENT_ID,
    PAYPAL_SECRET: REACT_APP_PAYPAL_SECRET,

    STRIPE_KEY: REACT_APP_STRIPE_KEY,
    STRIPE_SECRET: REACT_APP_STRIPE_SECRET,

    AUTH_KEY: 'auth-key',
    LOCALSTORAGE_EXPIRES_KEY: 'session-token-expiry',
    LOCALSTORAGE_EXPIRES_TIME: '30', // minutes
    JOB_USAGE: [
        '* Basic usage is granted under the shoot fee currently offered. The permitted usage is purely online, including website and PR, and social media for a maximal period of 12 months.',
        '* Band 1 usage permits the use of images online, packaging and point of sale in the UK and Ireland for a period of 12 months.',
        '* Band 2 permits the use of images as per band 1, with the addition of all print media including billboards, across Europe for a maximum period of three years.',
        '* Band 3 permits the worldwide use of images for five years. All print, all digital and TV commercials, cinematic release and in-flight use.',
        '* N/A: Usage is not required for this job. Either the material is not for commercial use or the job is a live event.',
        '* Full buyout - Select this option if you want to use the images across all media platforms, in perpetuity (forever). Only select this option if you truly require this level of usage. This unlimited usage is not usually welcomed by models unless a significant buyout payment is offered.',
    ],
    JOB_USAGE_SHORT: [
        'Basic usage - online 1 year *',
        'Band 1 - UK 1 years, below line *',
        'Band 2 - EU 3 years, print + below line *',
        'Band 3 - World 5 years, print + digital + TV *',
        'N/A *',
        'Full buyout *',
    ],
    cupSizes: [
        'A', 'AA', 'B', 'C', 'D', 'DD', 'E', 'F', 'FF', 'G', 'GG', 'H',
        'HH', 'I', 'J', 'JJ', 'K', 'KK', 'L', 'LL', 'M', 'MM', 'N',
    ],
};

export default app;
