import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Icon, { EllipsisOutlined } from '@ant-design/icons';
import { Button, EditButton, AddToBasketButton } from '../../../components/elements/FormElements';
import LocationIcon from '../../../assets/images/location.png';
import ModelBasket from '../../../assets/images/models-basket.png';
import history from '../../../core/utils/history';
import { UsersSvg, MessageSvg, AddModelSvg, PencilSvg } from '../../../assets/icons';
import './jobBox.scss';

const UsersIcon = props => <Icon component={UsersSvg} {...props} />;
const MessageIcon = props => <Icon component={MessageSvg} {...props} />;
const AddModelIcon = props => <Icon component={AddModelSvg} {...props} />;
const PencilIcon = props => <Icon component={PencilSvg} {...props} />;
// draft, pending, active, started, completed, rejected

const JobBox = ({ id, title, slug, status, location, firstDay, description, activeStep }) => {
    let link = `/job/${slug}/${activeStep || 'first'}`;
    if (status != 'draft') {
        link = `/job/${slug}`;
    }
    return (
        <div className="job-box">
            <Row>
                <Col span={22}>
                    <span className="job-box-title">
                        <Link to={link}>{title}</Link>
                    </span>
                    {status == 'active' || status == 'started' ? <span className="job-box-pill green">{status}</span> : null}
                    {status == 'pending' ? <span className="job-box-pill blue">{status}</span> : null}
                    {status == 'draft' ? <span className="job-box-pill">{status}</span> : null}
                    {status == 'rejected' ? <span className="job-box-pill red">REJECTED</span> : null}
                    {status == 'completed' ? <span className="job-box-pill orange">COMPLETED</span> : null}
                </Col>
                <Col span={2} style={{ textAlign: 'right' }}>
                    <Button className="job-box-button" icon={<EllipsisOutlined />} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <span className="job-box-location">
                        <img alt="location" src={LocationIcon} /> {location || 'None set'}
                    </span>
                    <span className="job-box-day">
                        <label>First Day:</label> {(firstDay && moment(firstDay, 'YYYY-MM-DD').format('DD/MM/YYYY')) || 'None set'}
                    </span>
                </Col>
            </Row>
            <Row>
                <Col span={24} className="job-box-desc">
                    {description}
                </Col>
            </Row>
            {status == 'active'
                ? (
                    <Row>
                        <Col xs={24} md={14} className="job-box-action">
                            <Button icon={<UsersIcon style={{ fontSize: 22 }} />} type="primary" label="Confirm Models" />
                            <Button icon={<MessageIcon style={{ fontSize: 22 }} />} label="Message Models" />
                        </Col>
                        <Col md={1}>
                            <span className="model-stats">
                                <AddModelIcon />
                            </span>
                        </Col>
                        <Col md={9}>
                            <span className="model-stats-count">
                                4 / 6
                            </span>
                        </Col>
                    </Row>
                )
                : null}
            {status == 'draft'
                ? (
                    <Row>
                        <Col xs={24} md={12} className="job-box-action">
                            <Button type="primary" icon={<PencilIcon style={{ fontSize: 22 }} />} label="Edit Job" onClick={() => history.push(link)} />

                        </Col>
                        <Col xs={24} md={12} className="model-stats-count">
                            <Link to="#">
                                <img alt="Add to basket" src={ModelBasket} width={38} height={32} /> 4 / 6
                            </Link>
                        </Col>
                    </Row>
                )
                : null}
        </div>
    );
};

export default JobBox;
