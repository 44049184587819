import React, { Component } from 'react';
import DOM from './afterHeader';

class AfterHeader extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    render() {
        return this.view(this.props);
    }
}

export default AfterHeader;
