import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Row, Input, Spin, Empty } from 'antd';
import moment from 'moment';
import MessageListItem from './partials/messageListItem';
import MessageHeader from './partials/messageHeader';
import MessageLineItem from './partials/messageLineItem';
import { getSelectedConversationRequest, sendNewMessageRequest } from '../../core/message/messageActions';
import './messages.scss';
import SendMessage from '../../assets/images/send-message.png';

let messengerInterval = null;

const Messenger = ({ messageList }) => {
    const messageSendInput = useRef(null);
    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const { jobId, modelId } = useParams();
    const messageDefaultState = (messageList.filter(message => modelId == message.user_two_id && message.job_id == jobId)[0] || {});
    const [selectedMessage, setSelectedMessage] = useState(messageDefaultState);
    const [newMessage, setNewMessage] = useState(null);
    const dispatch = useDispatch();
    const isFetching = useSelector(state => state.message.isFetching);
    const isConversationFetching = useSelector(state => state.message.isConversationFetching);
    const conversation = useSelector(state => state.message.conversation);
    const userId = useSelector(state => state.user.userId);
    const loadConversation = (messageId) => dispatch(getSelectedConversationRequest({ id: messageId }));

    const sendMessage = (messageId) => {
        dispatch(sendNewMessageRequest({ id: messageId, message: newMessage }));
        setNewMessage(null);
    };

    useEffect(() => {
        if (messageDefaultState && messageDefaultState.id) {
            // loadConversation(messageDefaultState.id);
            messengerInterval = setInterval(() => loadConversation(messageDefaultState.id), 15000);
        }
        return () => {
            if (messengerInterval != null) {
                clearInterval(messengerInterval);
                messengerInterval = null;
            }
        };
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [conversation]);

    const TODAY = moment().startOf('day');
    const YESTERDAY = moment().subtract(1, 'days').startOf('day');
    return (
        <Row className="message">
            <Col xs={24} md={8} className="message-sidebar">
                <Spin spinning={false}> {/* isFetching */}
                    {messageList.map((messageListRow, messageListRowKey) => {
                        const messageTime = moment(messageListRow.update_time);
                        let message = messageTime.format('HH:ss');
                        if (messageTime.isSame(YESTERDAY, 'd')) {
                            message = 'Yesterday';
                        } else if (!messageTime.isSame(TODAY, 'd')) {
                            message = messageTime.format('DD/MM/YYYY');
                        }
                        return (
                            <MessageListItem
                                key={messageListRowKey}
                                selected={messageListRow.id == selectedMessage?.id}
                                onClick={(e) => {
                                    setSelectedMessage({ ...messageListRow });
                                    loadConversation(messageListRow.id);
                                }}
                                image={`https://testapi.modlapp.com/${messageListRow.model_image}`}
                                time={message}
                                slug={messageListRow.slug}
                                full_name={messageListRow.profile2_full_name}
                                first_day={moment(messageListRow.first_day).format('DD/MM/YYYY')}
                                job_name={messageListRow.title} />
                        );
                    })}
                </Spin>
            </Col>
            <Col xs={0} md={16} className="conversation">
                <div className="message-header">
                    {selectedMessage && selectedMessage.id
                        ? (
                            <MessageHeader
                                slug={selectedMessage.slug}
                                image={`https://testapi.modlapp.com/${selectedMessage.model_image}`}
                                full_name={selectedMessage.profile2_full_name}
                                first_day={moment(selectedMessage.first_day).format('DD/MM/YYYY')}
                                job_name={selectedMessage.title} />
                        )
                        : null}
                </div>

                <Spin spinning={false}> {/* isConversationFetching */}
                    <div className="message-details">
                        {selectedMessage && selectedMessage.id
                            ? (
                                <>
                                    {conversation.map((message, messageKey) => (
                                        <MessageLineItem
                                            key={messageKey}
                                            date={message.create_time}
                                            message={message.data}
                                            me={message.sent_by == userId} />
                                    ))}
                                    <div className="message-type">
                                        <Input
                                            ref={messageSendInput}
                                            placeholder="Type your message..."
                                            value={newMessage}
                                            onChange={(e) => setNewMessage(e.target.value)}
                                            onPressEnter={(e) => {
                                                sendMessage(selectedMessage.id);
                                                e.target.value = '';
                                            }} />
                                        <img src={SendMessage}
                                            alt="Send Message"
                                            onClick={(e) => {
                                                sendMessage(selectedMessage.id);
                                            }} />
                                    </div>
                                </>

                            )
                            : <Empty />}
                        <div ref={messagesEndRef} />
                    </div>
                </Spin>

            </Col>
        </Row>
    );
};

export default Messenger;
