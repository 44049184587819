import React from 'react';
import PropTypes from 'prop-types';

export default class Shave extends React.Component {
    static defaultProps = {
        tag: 'p',
        character: '…',
        children: '',
    }

    state = {}

    componentDidMount() {
        // I know, I know, setState in `componentDidMount` will kill my cat and so on...
        // In this case this is exactly what we want, we cache original text
        // as `trimmedText`, also, setting the state will trigger a new render
        // and it will start the `componentDidUpdate` loop we want.
        this.setState({ trimmedText: this.props.children });
    }

    componentDidUpdate() {
        // We make React loop the rendering untill the component height is equal
        // or lower of the defined maxHeight
        this.updateText();
    }

    updateText = () => {
        const { element, state: { trimmedText }, props: { maxHeight } } = this;

        // Stop the loop once the component reaches the desired height
        if (element.offsetHeight > maxHeight) {
            const lastSpace = trimmedText.lastIndexOf(' ');
            // Avoid to trim the last word
            if (lastSpace >= 0) {
                this.setState({ trimmedText: trimmedText.substr(0, lastSpace) });
            }
        }
    }

    render() {
        const { props: { children, character, tag }, state: { trimmedText } } = this;

        return React.createElement(
            tag,
            {
                ref: element => (this.element = element),
                title: children,
            },
            trimmedText,
            children !== trimmedText && character
        );
    }

    static propTypes = {
        maxHeight: PropTypes.number.isRequired,
        character: PropTypes.string,
        children: PropTypes.string,
    }
}
