import { Record } from 'immutable';
import { findIndex } from 'underscore';

const {
    GET_JOB_LIST_REQUEST,
    GET_JOB_LIST_SUCCESS,
    GET_JOB_LIST_FAILURE,

    GET_JOB_REQUIREMENT_REQUEST,
    GET_JOB_REQUIREMENT_SUCCESS,
    GET_JOB_REQUIREMENT_FAILURE,

    UPDATE_JOB_BASKET_REQUEST,
    UPDATE_JOB_BASKET_SUCCESS,
    UPDATE_JOB_BASKET_FAILURE,

    GET_JOB_DETAILS_REQUEST,
    GET_JOB_DETAILS_SUCCESS,
    GET_JOB_DETAILS_FAILURE,

    GET_JOB_DAYS_REQUEST,
    GET_JOB_DAYS_SUCCESS,
    GET_JOB_DAYS_FAILURE,

    CLEAR_SELECTION_REQUEST,
    CLEAR_SELECTION_SUCCESS,
    CLEAR_SELECTION_FAILURE,

    CREATE_JOB_REQUEST,
    CREATE_JOB_SUCCESS,
    CREATE_JOB_FAILURE,

    SUBMIT_JOB_REQUEST,
    SUBMIT_JOB_SUCCESS,
    SUBMIT_JOB_FAILURE,

    GET_COMPLETE_JOB_REQUEST,
    GET_COMPLETE_JOB_SUCCESS,
    GET_COMPLETE_JOB_FAILURE,

    CONFIRM_JOB_MODEL_REQUEST,
    CONFIRM_JOB_MODEL_SUCCESS,
    CONFIRM_JOB_MODEL_FAILURE,

    DAYS_PRICE_CALCULATION,

} = require('./jobActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    daysIsFetching: false,
    jobs: [],
    jobsPagination: {
        currentPage: 1,
        pageCount: 0,
        perPageCount: 10,
        totalRecords: 0,
    },
    jobLocation: '',
    jobRequirement: {},
    jobDetails: {},
    jobFurtherDetails: {},
    matchingModels: [],
    basketModels: [],
    jobDays: {},
    jobDaysDetails: {},
    jobFee: 0,
    jobNoOfDays: 0,
    completeJob: null,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function jobReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_JOB_LIST_REQUEST:
    case GET_JOB_REQUIREMENT_REQUEST:
    case UPDATE_JOB_BASKET_REQUEST:
    case CLEAR_SELECTION_REQUEST:
    case CREATE_JOB_REQUEST:
    case SUBMIT_JOB_REQUEST:
    case GET_COMPLETE_JOB_REQUEST:
    case CONFIRM_JOB_MODEL_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);
    case GET_JOB_DETAILS_REQUEST:
    case GET_JOB_DAYS_REQUEST:
        const saveReq = !(payload && payload.params && payload.params != 'undefined');
        return state.set('isFetching', saveReq)
            .set('error', null);
    case DAYS_PRICE_CALCULATION:
        return state.set('daysIsFetching', payload);
    case GET_JOB_LIST_SUCCESS:
        const pagination = {};
        pagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
        pagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
        pagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
        pagination.perPageCount = payload.headers['x-pagination-per-page'] || 10;
        return state.set('isFetching', false)
            .set('jobs', payload.data)
            .set('jobsPagination', pagination);
    case SUBMIT_JOB_SUCCESS:
        return state.set('isFetching', false);
    case GET_COMPLETE_JOB_SUCCESS:
    case CONFIRM_JOB_MODEL_SUCCESS:
        return state.set('isFetching', false)
            .set('completeJob', payload.data);
    case GET_JOB_REQUIREMENT_SUCCESS:
    case CREATE_JOB_SUCCESS:
        const { requirements, location, matching_models, basket_models } = payload.data;
        return state.set('isFetching', false)
            .set('jobLocation', location)
            .set('jobRequirement', requirements)
            .set('matchingModels', matching_models)
            .set('basketModels', basket_models);
    case UPDATE_JOB_BASKET_SUCCESS:
        const { matchingModels } = state;
        matchingModels.forEach((matchingModel, matchingModelIndex) => {
            matchingModels[matchingModelIndex].in_basket = '0';
            payload.data.basket_models.forEach(model => {
                if (model.id == matchingModel.id) {
                    matchingModels[matchingModelIndex].in_basket = '1';
                }
            });
        });
        return state.set('isFetching', false)
            .set('basketModels', payload.data.basket_models);
    case GET_JOB_DETAILS_SUCCESS:
        return state.set('isFetching', false)
            .set('jobDetails', payload.data.job_details)
            .set('jobFurtherDetails', payload.data.job_further_details)
            .set('matchingModels', payload.data.matching_models)
            .set('basketModels', payload.data.basket_models);
    case GET_JOB_DAYS_SUCCESS:
        return state.set('isFetching', false)
            .set('daysIsFetching', false)
            .set('jobDaysDetails', payload.data.details)
            .set('jobDays', payload.data.days)
            .set('jobNoOfDays', payload.data.no_of_days)
            .set('jobFee', payload.data.jobFee)
            .set('matchingModels', payload.data.matching_models)
            .set('basketModels', payload.data.basket_models);
    case CLEAR_SELECTION_SUCCESS:
        return state.set('isFetching', false)
            .set('matchingModels', []);
    case GET_JOB_LIST_FAILURE:
    case GET_JOB_REQUIREMENT_FAILURE:
    case UPDATE_JOB_BASKET_FAILURE:
    case GET_JOB_DETAILS_FAILURE:
    case GET_JOB_DAYS_FAILURE:
    case CLEAR_SELECTION_FAILURE:
    case CREATE_JOB_FAILURE:
    case SUBMIT_JOB_FAILURE:
    case GET_COMPLETE_JOB_FAILURE:
    case CONFIRM_JOB_MODEL_FAILURE:
        return state.set('isFetching', false)
            .set('daysIsFetching', false)
            .set('isLoggedIn', false)
            .set('error', payload);
    default:
        return state;

    }
}
