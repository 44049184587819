/* global window:true */
import React, { Component, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Input, Row, Spin } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import MODLLogo from '../../assets/images/modl.svg';
import AfterHeader from '../../components/layout/AfterHeader';
import Layout from '../../components/layout/Layout';
import history from '../../core/utils/history';
import { myAccountRequest } from '../../core/user/userActions';
import './myAccount.scss';
import { Button } from '../../components/elements/FormElements';

const MyAccount = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(myAccountRequest());
        window.scrollTo(0, 0);
    }, []);

    const onFinish = (values) => {
        dispatch(myAccountRequest(values));
    };

    const myAccount = useSelector(state => state.user.userDetails);
    const backLink = (
        <Row align="middle">
            <Col xs={24} md={2}>
                <LeftOutlined style={{ fontSize: '24px' }} onClick={() => history.push('/dashboard')} />
            </Col>
            <Col xs={0} md={22}>
                <div className="back-link-header">My Account</div>
            </Col>
        </Row>
    );
    return (
        <Layout
            className="job"
            title={<div>Welcome</div>}
            headerTitle={<img alt="MODL" src={MODLLogo} />}
            afterHeader={<AfterHeader content={null} />}
            menu={[]}
            menuText="My Account"
            menuWidth={350}
            menuPlacement="left"
            // toolbar={[]}
            floatingHeader={false}
            // footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2019</div>}
            // floatingFooter
            showFooter={false}
            // breadcrumb={navigation}
            backLink={backLink}>
            <Spin size="large" spinning={false}>
                <div className="main-content" style={{ marginBottom: '32px' }}>
                    <Row style={{ marginTop: '32px', marginBottom: '24px' }}>
                        <Col xs={24}>
                            <h1 className="heading-review-page">Edit Your Account</h1>
                        </Col>
                    </Row>

                    <Form
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        layout="vertical"
                        onFinish={onFinish}
                        // form={form}
                        // onFieldsChange={onFilterFieldsChange} // {debounce(onFilterFieldsChange, 3000)}
                        initialValues={{
                            layout: 'vertical',
                            company_name: myAccount?.business_name || '',
                            chief_contact: myAccount?.full_name || '',
                            description: myAccount?.description || '',
                            telephone: myAccount?.telephone || '',
                            linkedin_url: myAccount?.linkedin_url || '',
                            website_url: myAccount?.website_url || '',
                            telephone_alt: myAccount?.telephone_alt || '',
                            company_number: myAccount?.company_number || '',
                            vat_number: myAccount?.vat_number || '',
                        }}>
                        <Form.Item label="Company / Trading name"
                            name="company_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Add a company / trading name',
                                },
                            ]}>
                            <Input placeholder="Company / Trading name" />
                        </Form.Item>
                        <Form.Item label="Chief Contact"
                            name="chief_contact"
                            rules={[
                                {
                                    required: true,
                                    message: 'Add a chief contact',
                                },
                            ]}>
                            <Input placeholder="Chief Contact" />
                        </Form.Item>
                        <Form.Item label="What does your business do?"
                            name="description">
                            <Input placeholder="What does your business do?" />
                        </Form.Item>
                        <Form.Item label="LinkedIn URL"
                            name="linkedin_url">
                            <Input placeholder="LinkedIn URL" />
                        </Form.Item>
                        <Form.Item label="Website URL"
                            name="website_url">
                            <Input placeholder="Website URL" />
                        </Form.Item>
                        <Form.Item label="Telephone number"
                            name="telephone">
                            <Input placeholder="Telephone number" />
                        </Form.Item>
                        <Form.Item label="Alternative telephone number"
                            name="telephone_alt">
                            <Input placeholder="Alternative telephone number" />
                        </Form.Item>
                        <Form.Item label="Company number (if applicable)"
                            name="company_number">
                            <Input placeholder="Company number (if applicable)" />
                        </Form.Item>
                        <Form.Item label="VAT number (if applicable)"
                            name="vat_number">
                            <Input placeholder="VAT number (if applicable)" />
                        </Form.Item>
                        <Row>
                            <Col xs={24}>
                                <Button type="primary" htmlType="submit" className="wide" label="Update Details" />
                            </Col>
                        </Row>
                    </Form>

                </div>
            </Spin>
        </Layout>
    );
};

export default MyAccount;
