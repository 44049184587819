import React, { useEffect, useState } from 'react';
import { Alert, Col, Row } from 'antd';
import moment from 'moment';
import { LeftOutlined } from '@ant-design/icons';
import FlipCountdown from '@rumess/react-flip-countdown';
import ModelBoxWithInfo from '../../../components/ModelBoxWithInfo';
import history from '../../../core/utils/history';
import LocationIcon from '../../../assets/images/location.png';
import PhoneIcon from '../../../assets/images/phone.png';

const {
    JOB_USAGE_SHORT,
} = require('../../../core/constants').default;

const StartedHeader = ({ completeJob }) => {
    const noOfModels = completeJob?.job_requirement?.no_of_models || 1;
    const approvedDate = completeJob?.approved_on;
    const cancellationDate = completeJob?.cancellation_date;
    const bookedModels = completeJob?.models?.selected || [];

    const [timer, setTimer] = useState('');
    const [timerMessage, setTimerMessage] = useState('');

    const calcDuration = (approvedTime, cancellationTime) => {
        const now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss'); // today's date
        const durationModels = moment.duration(moment(approvedTime, 'YYYY-MM-DD HH:mm:ss').add(48, 'hours').diff(now));
        // console.log('>>>>>>', durationBooker);
        if (durationModels > 0) {
            // Still waiting for models to reply
            const modelDays = durationModels.get('days');
            const modelHours = durationModels.get('hours');
            const modelMins = durationModels.get('minutes');
            const modelSeconds = durationModels.get('seconds');
            let timeString = '';
            if (modelDays > 0) {
                timeString += `${modelDays} days `;
            }
            if (modelHours > 0) {
                timeString += `${modelHours} hours `;
            }
            if (modelMins > 0) {
                timeString += `${modelMins} minutes `;
            }
            if (modelSeconds > 0 && timeString == '') {
                timeString += `${modelSeconds} seconds`;
            }
            const timeStringMsg = timeString != '' ? 'remaining for models to respond to your availability request' : '';
            setTimer(timeString);
            setTimerMessage(timeStringMsg);
        } else {
            const durationBooker = moment.duration(moment(cancellationTime, 'YYYY-MM-DD').diff(now));
            if (durationBooker > 0) {
                const bookerDays = durationBooker.get('days');
                const bookerHours = durationBooker.get('hours');
                const bookerMins = durationBooker.get('minutes');
                const bookerSeconds = durationBooker.get('seconds');
                let bookTimeString = '';
                if (bookerDays > 0) {
                    bookTimeString += `${bookerDays} days `;
                }
                if (bookerHours > 0) {
                    bookTimeString += `${bookerHours} hours `;
                }
                if (bookerMins > 0) {
                    bookTimeString += `${bookerMins} minutes `;
                }
                if (bookerSeconds > 0 && bookTimeString == '') {
                    bookTimeString += `${bookerSeconds} seconds`;
                }
                const bookTimeStringMsg = bookTimeString != '' ? 'remaining for you to confirm your models' : '';
                setTimer(bookTimeString);
                setTimerMessage(bookTimeStringMsg);
            }
        }

    };
    setInterval(() => calcDuration(approvedDate, cancellationDate), 1000);

    /* const now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss'); // today's date
    const durationModels = moment.duration(moment(approvedDate, 'YYYY-MM-DD HH:mm:ss').add(48, 'hours').diff(now));
    let endTime = moment(approvedDate, 'YYYY-MM-DD HH:mm:ss').add(48, 'hours').format('YYYY-MM-DD HH:mm:ss');
    let timerMessage = 'remaining for models to respond to your availability request';
    if (durationModels <= 0) {
        endTime = moment(cancellationDate, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss');
        timerMessage = 'remaining for you to confirm your models';
    } */

    return (
        <>
            <Row gutter={[3, 3]}>
                <Col md={5} xs={0}>
                    <span className="heading-main">{timer}</span>
                    {/* <FlipCountdown
                        size="extra-small"
                        titlePosition="bottom"
                        hideYear
                        hideMonth
                        // hideDay
                        // hideHour
                        // hideMinute
                        // hideSecond
                        endAt={endTime} // Date/Time
                    /> */}
                </Col>
                <Col md={19} xs={0}>
                    <div className="heading-sub">{timerMessage}</div>
                    <div className="heading-content">After which we will automatically release unbooked models and refund you any unallocated fees</div>
                </Col>
            </Row>
            <div className="booking-allocation">
                <div className="heading-title">{bookedModels.length} of {noOfModels}</div>
                <div className="heading-content">Pre-paid bookings allocated</div>
            </div>


        </>
    );
};

const StartedBackLink = ({ title }) => (
    <Row align="middle">
        <Col xs={24} md={2}>
            <LeftOutlined style={{ fontSize: '24px' }} onClick={() => history.push('/dashboard')} />
        </Col>
        <Col xs={0} md={22}>
            <div className="back-link-header">Confirm Models</div>
            <div className="back-link-content">{title}</div>
        </Col>
    </Row>
);

const StartedStatus = ({ completeJob }) => {
    const bookedModels = completeJob?.models?.selected || [];
    // const pendingModels = completeJob?.models?.pending || [];
    // const discardedModels = completeJob?.models?.discarded || [];
    // const awaitingModels = completeJob?.models?.awaiting || [];
    const noOfModels = completeJob?.job_requirement?.no_of_models || 1;
    const noOfJobDays = completeJob && completeJob.days ? completeJob.days.length : 0;
    const jobFirstDay = completeJob && completeJob.days && completeJob.days[0]
        ? moment(completeJob.days[0].date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : null;
    const usageRights = (completeJob && completeJob.usage_type) || 1;
    const workingDetails = completeJob?.job_requirement?.working_day_and_hours;
    let totalModelFee = 0;
    let totalJobPrice = 0;
    const usageFee = parseFloat(completeJob.total_usage_fees);
    if (completeJob && completeJob.days) {
        completeJob.days.forEach(jobDay => (totalModelFee += parseFloat(jobDay.total_fee)));
    }

    const priceSummary = [];
    for (let i = 1; i <= noOfModels; i += 1) {
        totalJobPrice += totalModelFee;
        priceSummary.push(<Row key={i} className="price-row">
            <Col xs={20}>Model {i}: Basic Job Fee</Col>
            <Col xs={4}>£{totalModelFee.toFixed(2)}</Col>
        </Row>);
    }
    totalJobPrice += usageFee;
    const modlCommission = totalJobPrice * 19 / 100;
    return (
        <>
            <div className="main-content" style={{ marginBottom: '47px' }}>
                <Row>
                    <Col span={24}>
                        <h1 className="heading-review-page">Models Booked ({bookedModels.length})</h1>
                    </Col>
                </Row>
                <Row style={{ marginTop: '22px' }}>
                    {bookedModels.map((bookedModel, bookedModelIndex) => (
                        <ModelBoxWithInfo
                            key={`booked-model-${bookedModelIndex}`}
                            jobId={completeJob?.id}
                            id={bookedModel.id}
                            name={bookedModel.full_name}
                            age={bookedModel.age}
                            gender={bookedModel.gender}
                            height={bookedModel.height}
                            src={bookedModel.image}
                            media={bookedModel.other_image}
                            status="booked" />
                    ))}
                </Row>
            </div>

            <div className="main-content" style={{ marginBottom: '32px' }}>
                <Row style={{ marginTop: '32px', marginBottom: '24px' }}>
                    <Col xs={24}>
                        <h1 className="heading-review-page">Job details</h1>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                    <Col span={24}>
                        <span className="review-subtitle">Job title</span>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '32px' }}>
                    <Col span={24}>
                        {completeJob?.title}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                    <Col span={24}>
                        <span className="review-subtitle">Description</span>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '32px' }}>
                    <Col span={24}>
                        {completeJob?.description}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                    <Col span={24}>
                        <span className="review-subtitle">Contact</span>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                    <Col span={24}>
                        <img src={LocationIcon} alt="Location" /> {completeJob?.location}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '32px' }}>
                    <Col span={24}>
                        <img src={PhoneIcon} alt="Phone" /> {completeJob?.contact}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '32px' }}>
                    <Col xs={8}>
                        <Row>
                            <Col xs={24} style={{ marginBottom: '16px' }}>
                                <span className="review-subtitle">Number of job days</span>
                            </Col>
                            <Col xs={24}>{noOfJobDays}</Col>
                        </Row>
                    </Col>
                    <Col xs={8}>
                        <Row>
                            <Col xs={24} style={{ marginBottom: '16px' }}>
                                <span className="review-subtitle">Job start day</span>
                            </Col>
                            <Col xs={24}>
                                {jobFirstDay}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={8}>
                        <Row>
                            <Col xs={24} style={{ marginBottom: '16px' }}>
                                <span className="review-subtitle">Usage</span>
                            </Col>
                            <Col xs={24}>
                                {JOB_USAGE_SHORT[usageRights - 1]}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                    <Col span={24}>
                        <span className="review-subtitle">Details about working days and hours</span>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '48px' }}>
                    <Col span={24}>
                        {workingDetails}
                    </Col>
                </Row>
            </div>

            <div className="main-content" style={{ marginBottom: '32px' }}>
                <Row style={{ marginBottom: '32px' }}>
                    <Col span={24}>
                        <h1 className="heading-review-page">Job fee offered</h1>
                    </Col>
                </Row>
                <Row className="price-row job-price-head-row">
                    <Col xs={24}>
                        <strong>Model(s) fee</strong>
                    </Col>
                </Row>
                {priceSummary}
                <Row className="price-row job-price-head-row">
                    <Col xs={24}>
                        <strong>Usage</strong>
                    </Col>
                </Row>
                <Row className="price-row">
                    <Col xs={20}>{JOB_USAGE_SHORT[completeJob?.usage_type]}</Col>
                    <Col xs={4}>£{usageFee.toFixed(2)}</Col>
                </Row>
                <Row className="price-row" style={{ marginTop: '20px' }}>
                    <Col xs={20}>Booking fee (19%)</Col>
                    <Col xs={4}>£{modlCommission.toFixed(2)}</Col>
                </Row>
                <Row className="form-total-row">
                    <Col xs={20}>Total</Col>
                    <Col xs={4}>£{totalJobPrice + modlCommission}</Col>
                </Row>
            </div>

        </>
    );
};

export {
    StartedBackLink,
    StartedStatus,
    StartedHeader,
};
