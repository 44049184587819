import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { isArray } from 'underscore';
import { Col, Row, Alert } from 'antd';
import moment from 'moment';
import { LeftOutlined } from '@ant-design/icons';
import { getJobDaysRequest, getJobDetailsRequest, submitJobRequest } from '../../../core/job/jobActions';
import ChevronRight from '../../../assets/images/arrow-orange.png';
import { Button, PayPalButton } from '../../../components/elements/FormElements';
import StepImage from '../../../components/ModelBox';
import LocationIcon from '../../../assets/images/location.png';
import PhoneIcon from '../../../assets/images/phone.png';

import TestimonialBox from '../../../components/elements/TestimonialBox';
import history from '../../../core/utils/history';

const {
    JOB_USAGE_SHORT,
} = require('../../../core/constants').default;

const RejectedHeader = () => null;

const RejectedBackLink = ({ title }) => (
    <Row align="middle">
        <Col xs={24} md={2}>
            <LeftOutlined style={{ fontSize: '24px' }} onClick={() => history.push('/dashboard')} />
        </Col>
        <Col xs={0} md={22}>
            <div className="back-link-header">Your job breif</div>
            <div className="back-link-content">{title}</div>
        </Col>
    </Row>
);

const RejectedStatus = ({ completeJob }) => {
    const dispatch = useDispatch();
    const { slug } = useParams();

    /**
     * From here down
     */
    const basketModels = completeJob?.models?.basket || [];
    const workingDetails = completeJob?.job_requirement?.working_day_and_hours;
    const noOfJobDays = completeJob && completeJob.days ? completeJob.days.length : 0;
    const noOfModels = (completeJob && completeJob.job_requirement && completeJob.job_requirement.no_of_models) || 1;
    const jobFirstDay = completeJob && completeJob.days && completeJob.days[0]
        ? moment(completeJob.days[0].date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : null;
    const usageRights = (completeJob && completeJob.usage_type) || 1;
    let totalModelFee = 0;
    let totalJobPrice = 0;
    const usageFee = parseFloat(completeJob.total_usage_fees);
    if (completeJob && completeJob.days) {
        completeJob.days.forEach(jobDay => (totalModelFee += parseFloat(jobDay.total_fee)));
    }

    const priceSummary = [];
    for (let i = 1; i <= noOfModels; i += 1) {
        totalJobPrice += totalModelFee;
        priceSummary.push(<Row key={i} className="price-row">
            <Col xs={20}>Model {i}: Basic Job Fee</Col>
            <Col xs={4}>£{totalModelFee.toFixed(2)}</Col>
        </Row>);
    }
    totalJobPrice += usageFee;
    const modlCommission = totalJobPrice * 19 / 100;
    const reason = <>
        <div>{completeJob?.reject_reason}</div>
        <div>You can adjust your job here and re-submit, or create a new one. You have not been charged for this job.</div>
    </>

    return (
        <>
            <div className="main-content" style={{ marginBottom: '47px' }}>
                <Row style={{ marginBottom: '32px' }}>
                    <Col span={24}>
                        <h1 className="heading-review-page">Job Rejected</h1>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '8px' }}>
                    <Col span={24}>
                        <Alert
                            message="Your job on MODL App has been declined by an administrator for the following reason:"
                            description={reason}
                            type="error"
                            showIcon />
                    </Col>
                </Row>
            </div>

            <div className="main-content" style={{ marginBottom: '32px' }}>
                <Row style={{ marginTop: '32px', marginBottom: '24px' }}>
                    <Col xs={24}>
                        <h1 className="heading-review-page">Job details</h1>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                    <Col span={24}>
                        <span className="review-subtitle">Job title</span>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '32px' }}>
                    <Col span={24}>
                        {completeJob?.title}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                    <Col span={24}>
                        <span className="review-subtitle">Description</span>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '32px' }}>
                    <Col span={24}>
                        {completeJob?.description}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                    <Col span={24}>
                        <span className="review-subtitle">Contact</span>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                    <Col span={24}>
                        <img src={LocationIcon} alt="Location" /> {completeJob?.location}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '32px' }}>
                    <Col span={24}>
                        <img src={PhoneIcon} alt="Phone" /> {completeJob?.contact}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '32px' }}>
                    <Col xs={8}>
                        <Row>
                            <Col xs={24} style={{ marginBottom: '16px' }}>
                                <span className="review-subtitle">Number of job days</span>
                            </Col>
                            <Col xs={24}>{noOfJobDays}</Col>
                        </Row>
                    </Col>
                    <Col xs={8}>
                        <Row>
                            <Col xs={24} style={{ marginBottom: '16px' }}>
                                <span className="review-subtitle">Job start day</span>
                            </Col>
                            <Col xs={24}>
                                {jobFirstDay}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={8}>
                        <Row>
                            <Col xs={24} style={{ marginBottom: '16px' }}>
                                <span className="review-subtitle">Usage</span>
                            </Col>
                            <Col xs={24}>
                                {JOB_USAGE_SHORT[usageRights - 1]}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                    <Col span={24}>
                        <span className="review-subtitle">Details about working days and hours</span>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '48px' }}>
                    <Col span={24}>
                        {workingDetails || 'N/A'}
                    </Col>
                </Row>
                {/* <Row style={{ marginBottom: '32px' }}>
                    <Col xs={24}>
                        <h1 className="heading-review-page">Models in your shortlist</h1>
                    </Col>
                </Row>
                {basketModels.map((model, modelIndex) => (
                    <StepImage
                        type="small"
                        key={`basket-model-${modelIndex}`}
                        disableActionIcon
                        src={model.image}
                        name={model.full_name}
                        selected={false}
                        onClick={() => {}} />
                ))} */}
            </div>

            <div className="main-content" style={{ marginBottom: '32px' }}>
                <Row style={{ marginBottom: '32px' }}>
                    <Col span={24}>
                        <h1 className="heading-review-page">Job fee offered</h1>
                    </Col>
                </Row>
                <Row className="price-row job-price-head-row">
                    <Col xs={24}>
                        <strong>Model(s) fee</strong>
                    </Col>
                </Row>
                {priceSummary}
                <Row className="price-row job-price-head-row">
                    <Col xs={24}>
                        <strong>Usage</strong>
                    </Col>
                </Row>
                <Row className="price-row">
                    <Col xs={20}>{JOB_USAGE_SHORT[completeJob?.usage_type]}</Col>
                    <Col xs={4}>£{usageFee.toFixed(2)}</Col>
                </Row>
                <Row className="price-row" style={{ marginTop: '20px' }}>
                    <Col xs={20}>Booking fee (19%)</Col>
                    <Col xs={4}>£{modlCommission.toFixed(2)}</Col>
                </Row>
                <Row className="form-total-row">
                    <Col xs={20}>Total</Col>
                    <Col xs={4}>£{totalJobPrice + modlCommission}</Col>
                </Row>
            </div>

        </>
    );
};

export {
    RejectedBackLink,
    RejectedStatus,
    RejectedHeader,
};
