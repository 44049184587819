import React from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'antd';
import { isObject } from 'underscore';
import Breadcrumb from '../Breadcrumb';
import Header from '../Header';
import Footer from '../Footer';
import './layout.css';
import icon from '../../../assets/favicon.ico';

const {
    APP_NAME,
} = require('../../../core/constants').default;

export default function () {
    const {
        children, title, toolbar, breadcrumb, className, menu, backLink,
        showHeader, beforeHeader, afterHeader, floatingHeader, headerTitle,
        showFooter, beforeFooter, afterFooter, floatingFooter, footerContent,
        menuPlacement, menuWidth, menuText,
    } = this.props;
    return (
        <div className={`page-wrap ${className}`}>
            <Helmet
                title={!isObject(title) ? title : title.props.children}
                titleTemplate={`%s - ${APP_NAME}`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: `${APP_NAME} website` },
                    { property: 'og:type', content: 'website' },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            {showHeader
                ? (
                    <Header
                        beforeheader={beforeHeader}
                        afterHeader={afterHeader}
                        floating={floatingHeader}
                        title={headerTitle || title}
                        toolbar={toolbar}
                        backLink={backLink}
                        menu={menu}
                        menuText={menuText}
                        menuPlacement={menuPlacement}
                        menuWidth={menuWidth} />
                )
                : null}
            <Layout.Content className="background">
                {children}
            </Layout.Content>
            {showFooter
                ? (
                    <Footer
                        beforeFooter={beforeFooter}
                        afterFooter={afterFooter}
                        floating={floatingFooter}>
                        {footerContent}
                    </Footer>
                )
                : null}
        </div>
    );
}
